import React, {useState, useEffect, memo} from 'react';
import ConfirmPopup from './ConfirmPopup.js';
import post from '../utils/post.js';
import $ from 'jquery';

function ExerciseWorkoutRec({item, rec, index2, sOrder, today, setData}) {
	const [idForDelete, setIdForDelete] = useState(0);
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);
	const [now, setNow] = useState(new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear());
	const [weights, setWeights] = useState({});
	const [cnt, setCnt] = useState({});
	const [s, setS] = useState({});
	const [m, setM] = useState({});

	useEffect(() => {
		const newWeights = {};
		const newCnt = {};
		const newS = {};
		const newM = {};

		item.recs.forEach((rec) => {
			newWeights['LIST_EXERCISE_REC_EDIT_WEIGHT_' + rec.ID] = rec.WEIGHT;
			newCnt['LIST_EXERCISE_REC_EDIT_CNT_' + rec.ID] = rec.CNT;
			newM['LIST_EXERCISE_REC_EDIT_M_' + rec.ID] = rec.TIMESEC_M;
			newS['LIST_EXERCISE_REC_EDIT_S_' + rec.ID] = rec.TIMESEC_S;
		});

		setNow(new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear());

		setWeights((prevWeights) => ({...prevWeights, ...newWeights}));
		setCnt((prevCnt) => ({...prevCnt, ...newCnt}));
		setM((prevM) => ({...prevM, ...newM}));
		setS((prevS) => ({...prevS, ...newS}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rec]);

	const handleConfirmPopup = (id) => {
		setIdForDelete(id);
		setShowConfirmPopup(true);
	};

	const closeConfirmPopup = () => {
		setShowConfirmPopup(false);
	};

	const deleteConfirmPopup = () => {
		post('deleteExerciseWorkout', {DT: today, ID: idForDelete})
			.then((res) => {
				setData(res.data);
				closeConfirmPopup();
			})
			.catch((error) => {
				console.error(error);
				closeConfirmPopup();
			});
	};

	const handleEdit = (id) => {
		if ($('#LIST_EXERCISE_REC_LBL_' + id).is(':visible') === true) {
			$('#LIST_EXERCISE_REC_LBL_' + id).hide();
			$('#LIST_EXERCISE_REC_EDIT_' + id).slideDown(300);
		} else {
			$('#LIST_EXERCISE_REC_EDIT_' + id).hide();
			$('#LIST_EXERCISE_REC_LBL_' + id).slideDown(300);
		}
	};

	const handleEditSave = (id, typeId) => {
		var cntV = 0;
		var weightsV = 0;
		var mV = 0;
		var sV = 0;

		if (typeId === 1) {
			cntV = cnt['LIST_EXERCISE_REC_EDIT_CNT_' + id];
			weightsV = weights['LIST_EXERCISE_REC_EDIT_WEIGHT_' + id];
		}

		if (typeId === 2) {
			mV = m['LIST_EXERCISE_REC_EDIT_M_' + id];
			sV = s['LIST_EXERCISE_REC_EDIT_S_' + id];
		}

		post('saveExerciseWorkoutOld', {DT: today, ID: id, CNT: cntV, WEIGHT: weightsV, TIMESEC_M: mV, TIMESEC_S: sV})
			.then((res) => {
				handleEdit(id);
				setData(res.data);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<>
			<ConfirmPopup showConfirmPopup={showConfirmPopup} showButtonDelete deleteConfirmPopup={deleteConfirmPopup} closeConfirmPopup={closeConfirmPopup} infoHeader="Smazání záznamu" infoMain="Opravdu chceš smazat tento záznam?" />

			<div key={'workouteRec_' + index2}>
				<div className="list">
					<div className="list_in fnt_15">
						<div className="zero h05" />

						{now === today ? <img className="icon1 edit fl_l cr_p mr_1 mt_01" onClick={() => handleEdit(rec.ID)} alt="" /> : <></>}

						<div className="fl_l">
							{sOrder}. {rec.CRDT_TMS}
						</div>

						{now === today ? (
							<div className="fl_r pos_r mr_1" style={{width: '3rem'}}>
								<img className="icon1 del small cr_p" onClick={() => handleConfirmPopup(rec.ID)} alt="" />
							</div>
						) : (
							<></>
						)}

						<div className="fl_r" id={'LIST_EXERCISE_REC_LBL_' + rec.ID} style={{paddingRight: '3.8rem'}}>
							{item.TYPEID === 1 ? (
								<>
									{rec.CNT} x {rec.WEIGHT} kg
								</>
							) : (
								<>{rec.TIMESECS}</>
							)}
						</div>
						<div className="fl_r hidden" id={'LIST_EXERCISE_REC_EDIT_' + rec.ID} style={{paddingRight: '3.8rem'}}>
							{item.TYPEID === 1 ? (
								<>
									<img className="icon1 ok1 fl_r cr_p ml_1 mt_07" onClick={() => handleEditSave(rec.ID, 1)} alt="" />
									<span className="fl_r ml_05 mr_05 mt_05">kg</span>
									<div className="fl_r" style={{width: '6rem'}}>
										<input
											className="input-text"
											id={'LIST_EXERCISE_REC_EDIT_WEIGHT_' + rec.ID}
											type="number"
											step={0.01}
											maxLength={5}
											value={weights['LIST_EXERCISE_REC_EDIT_WEIGHT_' + rec.ID] || ''}
											onChange={(e) =>
												setWeights({
													...weights,
													['LIST_EXERCISE_REC_EDIT_WEIGHT_' + rec.ID]: e.target.value,
												})
											}
										/>
									</div>
									<span className="fl_r ml_05 mr_05 mt_05">x</span>
									<div className="fl_r" style={{width: '6rem'}}>
										<input
											className="input-text"
											id={'LIST_EXERCISE_REC_EDIT_CNT_' + rec.ID}
											type="number"
											maxLength={3}
											value={cnt['LIST_EXERCISE_REC_EDIT_CNT_' + rec.ID] || ''}
											onChange={(e) =>
												setCnt({
													...cnt,
													['LIST_EXERCISE_REC_EDIT_CNT_' + rec.ID]: e.target.value,
												})
											}
										/>
									</div>
								</>
							) : (
								<>
									<img className="icon1 ok1 fl_r cr_p ml_1 mt_07" onClick={() => handleEditSave(rec.ID, 2)} alt="" />
									<div className="fl_r" style={{width: '6rem'}}>
										<input
											className="input-text"
											id={'LIST_EXERCISE_REC_EDIT_S_' + rec.ID}
											type="number"
											maxLength={2}
											value={s['LIST_EXERCISE_REC_EDIT_S_' + rec.ID] || ''}
											onChange={(e) =>
												setS({
													...s,
													['LIST_EXERCISE_REC_EDIT_S_' + rec.ID]: e.target.value,
												})
											}
										/>
									</div>
									<span className="fl_r ml_05 mr_05 mt_05">:</span>
									<div className="fl_r" style={{width: '6rem'}}>
										<input
											className="input-text"
											id={'LIST_EXERCISE_REC_EDIT_M_' + rec.ID}
											type="number"
											maxLength={3}
											value={m['LIST_EXERCISE_REC_EDIT_M_' + rec.ID] || ''}
											onChange={(e) =>
												setM({
													...m,
													['LIST_EXERCISE_REC_EDIT_M_' + rec.ID]: e.target.value,
												})
											}
										/>
									</div>
								</>
							)}
						</div>
						<div className="zero h05" />
					</div>
				</div>
				<div className="zero h02" />
			</div>
		</>
	);
}

export default memo(ExerciseWorkoutRec);
