import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';

//import notificationSound from '../audio1.wav';
import notificationSound2 from '../audio2.wav';

//const audio1 = new Audio(notificationSound);
const audio2 = new Audio(notificationSound2);

export default function Exercise({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [started, setStarted] = useState(false);
	const [timerCntId, setTimerCntId] = useState(null);
	const [counter, setCounter] = useState({time1: 0, time2: 0, time3: 0, round: 0, actTime: '00:00', actMsg: '', actColor: 'fnt_orange', actRound: 0});

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		setLoading(true);

		post('getTimerNew', {})
			.then((res) => {
				setData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	function convertSeconds(s) {
		let min = Math.floor(s / 60);
		let sec = s % 60;
		min = min.toString().length === 1 ? '0' + min.toString() : min.toString();
		sec = sec.toString().length === 1 ? '0' + sec.toString() : sec.toString();
		return min + ':' + sec;
	}

	function setTime1Val(val) {
		data.TIMER_TIME1 += val;
		if (data.TIMER_TIME1 < 10) {
			data.TIMER_TIME1 = 10;
		}
		setData({...data});
	}

	function setTime2Val(val) {
		data.TIMER_TIME2 += val;
		if (data.TIMER_TIME2 < 0) {
			data.TIMER_TIME2 = 0;
		}
		setData({...data});
	}

	function setTime3Val(val) {
		data.TIMER_TIME3 += val;
		if (data.TIMER_TIME3 < 0) {
			data.TIMER_TIME3 = 0;
		}
		setData({...data});
	}

	function setRoundVal(val) {
		data.TIMER_ROUND += val;
		if (data.TIMER_ROUND < 1) {
			data.TIMER_ROUND = 1;
		}
		setData({...data});
	}

	useEffect(() => {
		if (started) {
			startTimer();
		} else {
			if (timerCntId) {
				clearTimeout(timerCntId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [started]);

	const start = () => {
		let mM = 'Makej !!!';
		let mC = 'fnt_red';
		let mT = data.TIMER_TIME1;

		if (data.TIMER_TIME3 > 0) {
			mM = 'Připrav se !!!';
			mC = 'fnt_orange';
			mT = data.TIMER_TIME3;
		}

		setCounter({time1: data.TIMER_TIME1, time2: data.TIMER_TIME2, time3: data.TIMER_TIME3, round: 1, actRound: 1, actTime: convertSeconds(mT), actMsg: mM, actColor: mC});

		setStarted(true);
		countValues();

		setLoading(true);

		post('saveTimerStart', {
			ROUND: data.TIMER_ROUND,
			TIME1: data.TIMER_TIME1,
			TIME2: data.TIMER_TIME2,
			TIME3: data.TIMER_TIME3,
			SOUND: data.TIMER_SOUND,
		})
			.then((res) => {
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
			});
	};

	function resize(el, factor) {
		if (!el) return;

		var width = el.offsetWidth;

		if (typeof factor == 'undefined') {
			factor = 5;
		}

		el.style.fontSize = ((width / factor) | 0) + 'px';
	}

	useEffect(() => {
		resize(document.getElementById('ACT_TIMER'), 3);
	}, [counter.actTime]);

	function countValues() {
		//console.log('ok');
		//audio1.play();

		if (counter.time3 > 0) {
			counter.time3--;
			counter.actMsg = 'Připrav se !!!';
			counter.actColor = 'fnt_orange';
			counter.actTime = convertSeconds(counter.time3);

			if (parseInt(counter.time3) === 0) {
				counter.actMsg = 'Makej !!!';
				counter.actColor = 'fnt_red';

				if (parseInt(data.TIMER_SOUND) === 1) {
					audio2.play();
				}
			}
		} else {
			if (counter.time1 > 0) {
				counter.time1--;
				counter.actMsg = 'Makej !!!';
				counter.actColor = 'fnt_red';
				counter.actTime = convertSeconds(counter.time1);

				if (parseInt(counter.time1) === 0 && counter.time2 > 0 && counter.round < data.TIMER_ROUND) {
					counter.actMsg = 'Odpočívej !!!';
					counter.actColor = 'fnt_green';

					if (parseInt(data.TIMER_SOUND) === 1) {
						audio2.play();
					}
				}

				if (parseInt(counter.time1) === 0 && parseInt(counter.time2) === 0 && counter.round < data.TIMER_ROUND) {
					if (parseInt(data.TIMER_SOUND) === 1) {
						audio2.play();
					}
				}

				if (parseInt(counter.time1) === 0 && parseInt(counter.round) === parseInt(data.TIMER_ROUND)) {
					counter.actMsg = 'Hotovo !!!';
					counter.actColor = 'fnt_green';

					if (parseInt(data.TIMER_SOUND) === 1) {
						audio2.play();
					}
				}
			} else {
				if (counter.time2 > 0 && counter.round < data.TIMER_ROUND) {
					counter.time2--;
					counter.actMsg = 'Odpočívej !!!';
					counter.actColor = 'fnt_green';
					counter.actTime = convertSeconds(counter.time2);

					if (parseInt(counter.time2) === 0) {
						counter.actMsg = 'Makej !!!';
						counter.actColor = 'fnt_red';

						if (parseInt(data.TIMER_SOUND) === 1) {
							audio2.play();
						}
					}
				} else {
					if (counter.round < data.TIMER_ROUND) {
						counter.round++;
						counter.actRound++;
						counter.time1 = data.TIMER_TIME1;
						counter.time2 = data.TIMER_TIME2;

						counter.time1--;
						counter.actMsg = 'Makej !!!';
						counter.actColor = 'fnt_red';
						counter.actTime = convertSeconds(counter.time1);

						if (parseInt(counter.time1) === 0 && parseInt(counter.round) === parseInt(data.TIMER_ROUND)) {
							counter.actMsg = 'Hotovo !!!';
							counter.actColor = 'fnt_green';
						}
					} else {
						counter.actMsg = 'Hotovo !!!';
						counter.actColor = 'fnt_green';

						if (timerCntId) {
							clearTimeout(timerCntId);
						}
					}
				}
			}
		}
	}

	function startTimer() {
		if (timerCntId) {
			clearTimeout(timerCntId);
		}

		const newTimerId = setTimeout(() => {
			startTimer();
			countValues();
		}, 1000);

		setTimerCntId(newTimerId);
	}

	const stop = () => {
		setStarted(false);
		if (timerCntId) {
			clearTimeout(timerCntId);
		}
	};

	const setSoundVal = (e) => {
		e.preventDefault();
		data.TIMER_SOUND = parseInt(data.TIMER_SOUND) === 1 ? 0 : 1;
		setData({...data});
	};

	let time1 = convertSeconds(data.TIMER_TIME1);
	let time2 = convertSeconds(data.TIMER_TIME2);
	let time3 = convertSeconds(data.TIMER_TIME3);
	let round = data.TIMER_ROUND;
	let timeTotal = convertSeconds(data.TIMER_TIME1 * data.TIMER_ROUND + data.TIMER_TIME2 * (data.TIMER_ROUND - 1));

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					{!started ? (
						<>
							<div className="list">
								<div className="list_in">
									<div className="fl_l txt_l fnt_16">Čas kola</div>
									<div className="zero h1" />
									<div className="fl_l txt_l fnt_3">{time1}</div>
									<div className="fl_r">
										<button className="button mr_1" onClick={() => setTime1Val(-10)}>
											-10
										</button>
										<button className="button mr_1" onClick={() => setTime1Val(-1)}>
											-1
										</button>
										<button className="button mr_1" onClick={() => setTime1Val(1)}>
											+1
										</button>
										<button className="button" onClick={() => setTime1Val(10)}>
											+10
										</button>
									</div>
									<div className="zero h0" />
								</div>
							</div>
							<div className="zero h0" />

							<div className="list">
								<div className="list_in">
									<div className="fl_l txt_l fnt_16">Čas odpočinku</div>
									<div className="zero h1" />
									<div className="fl_l txt_l fnt_3">{time2}</div>
									<div className="fl_r">
										<button className="button mr_1" onClick={() => setTime2Val(-10)}>
											-10
										</button>
										<button className="button mr_1" onClick={() => setTime2Val(-1)}>
											-1
										</button>
										<button className="button mr_1" onClick={() => setTime2Val(1)}>
											+1
										</button>
										<button className="button" onClick={() => setTime2Val(10)}>
											+10
										</button>
									</div>
									<div className="zero h0" />
								</div>
							</div>
							<div className="zero h0" />

							<div className="list">
								<div className="list_in">
									<div className="fl_l txt_l fnt_16">Počet kol</div>
									<div className="zero h1" />
									<div className="fl_l txt_l fnt_3">{round}</div>
									<div className="fl_r fnt_16">
										<button className="button mr_1" onClick={() => setRoundVal(-1)}>
											-1
										</button>
										<button className="button" onClick={() => setRoundVal(1)}>
											+1
										</button>
									</div>
									<div className="zero h0" />
								</div>
							</div>
							<div className="zero h0" />

							<div className="list">
								<div className="list_in">
									<div className="fl_l txt_l fnt_16">Odložit start o</div>
									<div className="zero h1" />
									<div className="fl_l txt_l fnt_3">{time3}</div>
									<div className="fl_r">
										<button className="button mr_1" onClick={() => setTime3Val(-10)}>
											-10
										</button>
										<button className="button mr_1" onClick={() => setTime3Val(-1)}>
											-1
										</button>
										<button className="button mr_1" onClick={() => setTime3Val(1)}>
											+1
										</button>
										<button className="button" onClick={() => setTime3Val(10)}>
											+10
										</button>
									</div>
									<div className="zero h0" />
								</div>
							</div>
							<div className="zero h0" />

							<div className="list">
								<div className="list_in">
									<div className="fl_l txt_l fnt_16">Délka tréninku</div>
									<div className="zero h1" />
									<div className="fl_l txt_l fnt_3">{timeTotal}</div>
									<div className="zero h0" />
								</div>
							</div>
							<div className="zero h1" />

							<div key="SOUND">
								<div className="fl_l ml_1 cr_p" onClick={(e) => setSoundVal(e)}>
									<button className={`button check ${parseInt(data.TIMER_SOUND) === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
									<span className="fl_l mt_01">Zvuky</span>
								</div>
							</div>
							<div className="zero h2" />

							<button className="button big green" onClick={() => start()}>
								Start
							</button>
						</>
					) : (
						<>
							<button className="button big red fl_l" onClick={() => stop()}>
								Zpět
							</button>

							<div className={`fl_l ml_1 fnt_3 mt_05 ${counter.actColor}`}>
								<b>{counter.actMsg}</b>
							</div>

							<div className="fl_r fnt_4">
								<b>
									{counter.actRound}/{round}
								</b>
							</div>

							<div className="zero h0" />

							<div className="fnt_10" id="ACT_TIMER">
								<b>{counter.actTime}</b>
							</div>
							<div className="zero h0" />
						</>
					)}
				</div>
			</div>
		</>
	);
}
