import React, {useState, useEffect} from 'react';

export default function Select({show, close, value, options, showNone = true, returnValue}) {
	const [search, setSearch] = useState('');
	const [searchOptions, setSearchOptions] = useState([]);

	useEffect(() => {
		setSearchOptions(options);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	const setVals = (e) => {
		setSearch(e.target.value);

		let pom = options.filter((item) => {
			return item.NAME.toLowerCase().includes(e.target.value.toLowerCase());
		});

		setSearchOptions(pom);
	};

	const list = searchOptions?.map((item, index) => {
		return (
			<div
				className={`usermenu_item cr_p ${parseInt(value) === parseInt(item.ID) ? 'active' : ''}`}
				style={{paddingLeft: '0.5rem', width: 'calc(100% - 1.4rem)'}}
				key={'item_' + index}
				onClick={() => {
					returnValue(item);
					close();
				}}
			>
				<div className="list_in">
					<div className="txt_l">{item.NAME}</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	return (
		<>
			{show ? (
				<div className="popup" style={{zIndex: '2000'}}>
					<div className="popup_content" style={{width: '35rem'}}>
						<div className="popup_content_in_head">
							<div className="popup_content_in_head_in">Výběr</div>
							<img
								alt=""
								className="popup_content_in_head_in-close"
								onClick={() => {
									close();
								}}
							/>
						</div>
						<div className="popup_content_in_body">
							<div className="popup_content_in_body_in" style={{padding: '1rem 1rem 0rem 1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
								<input className="input-text" type="text" maxLength={100} placeholder="Vyhledat" value={search} onChange={(e) => setVals(e)} />
							</div>

							<div className="popup_content_in_body_in" style={{padding: '0rem 1rem 0rem 1rem', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
								<div className="zero h1" />

								{showNone ? (
									<div
										className={`usermenu_item cr_p ${parseInt(value) === 0 ? 'active' : ''}`}
										style={{paddingLeft: '0.5rem', width: 'calc(100% - 1.4rem)'}}
										key={'item_0'}
										onClick={() => {
											returnValue({ID: 0, NAME: '[None]'});
											close();
										}}
									>
										<div className="list_in">
											<div className="txt_l">[None]</div>
											<div className="zero h0" />
										</div>
									</div>
								) : (
									''
								)}

								{list}

								<div className="zero h1" />
							</div>
						</div>
						<div className="popup_content_in_foot">
							<div className="popup_content_in_foot_in">
								<button
									className="button"
									onClick={() => {
										close();
									}}
								>
									Zpět
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
