export default function validatePassword(password, password2) {
	let errorMessages = {password: '', password2: ''};
	if (password === '') {
		errorMessages.password = 'Zadej heslo';
	}
	if (password2 === '') {
		errorMessages.password2 = 'Povinné';
	}
	if (password !== password2) {
		errorMessages.password2 = 'Různé';
	}
	if (password.length < 6) {
		errorMessages.password = 'Alespoň 6 znaků';
	}
	return errorMessages;
}
