import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';

export default function MyPayment({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0);
	const [loadedCnt, setLoadedCnt] = useState(0);

	useEffect(() => {
		let search = '';

		if (typeof window.location.pathname.split('/')[2] != 'undefined') {
			search = decodeURI(window.location.pathname.split('/')[2]);
			setSearchValue(search);
		}

		getData(search, offset);

		if (search !== '') {
			window.history.pushState({}, document.title, '/userlog');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadNext = () => {
		setOffset((prevOffset) => {
			let newOffset = prevOffset + usrInfo.LIST_ROWS;
			getData(searchValue, prevOffset + usrInfo.LIST_ROWS);
			return newOffset;
		});
	};

	const onChangeSearch = (value) => {
		setSearchValue(value);
		setOffset(0);
		getData(value, 0);
	};

	const getData = (search, offset) => {
		setLoading(true);

		post('getListMyPayment', {search: search, offset: offset})
			.then((res) => {
				if (offset === 0) {
					setData(res.data.data);
				} else {
					setData((prevRows) => {
						const updatedRows = [...data, ...res.data.data];
						return updatedRows;
					});
				}

				setLoadedCnt(res.data.length);

				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	let list = [];

	data?.forEach((line, index) => {
		list.push(
			<div className="list" key={index}>
				<div className="list_in">
					<div className="fl_l txt_l">
						{line.PAYMENTDTS} {line.USR}
						{line.NOTE !== '' ? <span className=""> - {line.NOTE}</span> : ''}
					</div>
					<div className="fl_r ml_1 cr_p" onClick={() => window.open(line.PDF, '_blank')}>
						<img className="icon1 pdf" alt="" />
						<span className="ml_05">PDF</span>
					</div>
					<div className="fl_r">{line.AMOUNTS}</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showSearch searchValue={searchValue} onChangeSearch={(e) => onChangeSearch(e)}></Search>

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					{list}

					{parseInt(loadedCnt) === parseInt(usrInfo.LIST_ROWS) ? (
						<button className="button small loadNext" onClick={() => loadNext()}>
							Načíst další
						</button>
					) : (
						<span className="fnt_12 loadNext">Byly načteny všechny záznamy</span>
					)}
				</div>
			</div>
		</>
	);
}
