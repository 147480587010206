import React from 'react';
import {Link} from 'react-router-dom';

export default function NotFound() {
	return (
		<div className="login">
			<div className="zero h2" />
			<img className="login_logo" alt="" />
			<div className="zero h2" />

			<div className="login_container">
				<div className="zero h2"></div>
				<span>Stránka nenalezena</span>
				<div className="zero h2"></div>
				<Link to={'/'} key="lnk_login" className="lnk1">
					Zpět na přihlášení
				</Link>
				<div className="zero h2"></div>
			</div>
		</div>
	);
}
