import React, {useState} from 'react';
import Select from './Select.js';

const FormSelect = React.forwardRef(({label, value, valueLabel, setValue, error, placeholder = '', maxLength, options, showNone, disabled = false}, ref) => {
	const [showSel, setShowSel] = useState(false);

	if (placeholder === '') {
		placeholder = label;
	}

	if (!maxLength) {
		maxLength = 100;
	}

	const showSelect = () => {
		if (disabled) {
			return;
		}

		setShowSel(true);
	};

	const closeSelect = () => {
		setShowSel(false);
	};

	const returnValue = (val) => {
		setValue(val);
	};

	return (
		<>
			<div className="input-div">
				<div className="input-label">{label}</div>
				<div className="input-error">{error}</div>
				<input className={`input-text ${disabled ? 'cr_n' : 'cr_p'}`} type="text" maxLength={maxLength} placeholder={placeholder} ref={ref} value={valueLabel} onChange={(e) => setValue(e.target.value)} readOnly onClick={() => showSelect()} />
			</div>

			<Select show={showSel} close={closeSelect} value={value} valueLabel={valueLabel} options={options} showNone={showNone} returnValue={returnValue} />
		</>
	);
});

export default FormSelect;
