import axios from 'axios';

export default function post(act, data) {
	return new Promise((resolve, reject) => {
		let url = window.location.protocol + `//${window.location.hostname}/progressgym/rest/index.php?act=${act}`;

		if (window.location.protocol === 'https:') {
			url = window.location.protocol + `//${window.location.hostname}/rest/index.php?act=${act}`;
		}

		axios({
			method: 'POST',
			withCredentials: true,
			data: data,
			headers: {'Content-Type': 'multipart/form-data'},
			url: url,
		})
			.then((response) => {
				if (!response.data.token && response.data.usrId > -1) {
					if (act !== 'login' && act !== 'forget' && act !== 'restoreValidCode' && act !== 'restore' && act !== 'register' && act !== 'activate') {
						window.location.pathname = '/';
					}
				}
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}
