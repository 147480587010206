import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import FormText from './FormText.js';
import FormTextArea from './FormTextArea.js';
import FormSelect from './FormSelect.js';

export default function WorkoutNew({showNew, closeNew, updateWorkoutRowNew, dataMuscles, dataWorkoutOffic}) {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [note, setNote] = useState('');
	const [typeId, setTypeId] = useState(1);
	const [typeId2, setTypeId2] = useState(0);
	const [withmyweight, setWithmyweight] = useState(0);
	const [musclegroupId, setMusclegroupId] = useState(0);
	const [musclegroupName, setMusclegroupName] = useState('[None]');
	const [workoutOfficId, setWorkoutOfficId] = useState(0);
	const [workoutOfficName, setWorkoutOfficName] = useState('[None]');

	const nameRef = useRef(null);
	const noteRef = useRef(null);
	const musclegroupRef = useRef(null);
	const workoutOfficRef = useRef(null);

	useEffect(() => {
		setName('');
		setNote('');
		setTypeId(1);
		setTypeId2(0);
		setWithmyweight(0);
		setMusclegroupId(0);
		setMusclegroupName('[None]');
		setWorkoutOfficId(0);
		setWorkoutOfficName('[None]');
	}, [showNew]);

	const handleSubmit = (e) => {
		e.preventDefault();

		setNameError('');

		let localError = 0;

		if (!name) {
			localError = 1;
			setNameError('povinné');
			nameRef.current.focus();
		}

		if (localError === 0) {
			setLoading(true);

			post('saveWorkoutNew', {NAME: name, NOTE: note, TYPEID: typeId, TYPEID2: typeId2, WITHMYWEIGHT: withmyweight, MUSCLEID: musclegroupId, WORKOUTOFFICID: workoutOfficId})
				.then((res) => {
					closeNew();
					updateWorkoutRowNew(res.data);
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const setTypeIdVal = (e, val) => {
		e.preventDefault();
		setTypeId(val);

		if (parseInt(val) === 1) {
			setTypeId2(0);
			setTypeId2(0);
		}

		if (parseInt(val) === 2 && parseInt(typeId) !== 2) {
			setWithmyweight(0);
			setTypeId2(1);
		}
	};

	const setTypeId2Val = (e, val) => {
		e.preventDefault();

		if (parseInt(typeId) === 2) {
			setTypeId2(val);
		}
	};

	const setWithmyweightVal = (e, val) => {
		e.preventDefault();

		if (parseInt(typeId) === 1) {
			setWithmyweight((oldValue) => {
				if (parseInt(val) === parseInt(oldValue)) {
					return 0;
				} else {
					return val;
				}
			});
		}
	};

	const setMusclegroup = (val) => {
		setMusclegroupId(val.ID);
		setMusclegroupName(val.NAME);
	};

	const setWorkoutOffic = (val) => {
		setWorkoutOfficId(val.ID);
		setWorkoutOfficName(val.NAME);
	};

	return (
		<>
			{showNew ? (
				<div className="popup">
					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">Nový cvik</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeNew();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in">
									<FormText type="text" label="Název" value={name} setValue={setName} ref={nameRef} error={nameError} />
									<div className="zero h0" />

									<FormTextArea type="text" label="Poznámka" height="10rem" value={note} setValue={setNote} ref={noteRef} error="" />
									<div className="zero h0" />

									<FormSelect type="text" label="Svalová partie" value={musclegroupId} valueLabel={musclegroupName} setValue={setMusclegroup} options={dataMuscles} ref={musclegroupRef} error="" />
									<div className="zero h0" />

									<FormSelect type="text" label="Oficiální cvik" value={workoutOfficId} valueLabel={workoutOfficName} setValue={setWorkoutOffic} options={dataWorkoutOffic} ref={workoutOfficRef} error="" />
									<div className="zero h1" />

									<div className="fl_l" style={{width: '48%'}}>
										<div key="TYPEID1">
											<div className="fl_l mr_2 cr_p" onClick={(e) => setTypeIdVal(e, 1)}>
												<button className={`button check ${parseInt(typeId) === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
												<span className="fl_l mt_02">Opakování, váha</span>
											</div>
										</div>
										<div className="zero h1" />
										<div key="TYPEID2">
											<div className="fl_l mr_2 cr_p" onClick={(e) => setTypeIdVal(e, 2)}>
												<button className={`button check ${parseInt(typeId) === 2 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
												<span className="fl_l mt_02">Čas</span>
											</div>
										</div>
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<div key="TYPEID1">
											<div className={`fl_l mr_2 cr_p ${parseInt(typeId) === 2 ? 'button_disabled' : ''}`} onClick={(e) => setWithmyweightVal(e, 1)}>
												<button className={`button check ${parseInt(withmyweight) === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
												<span className="fl_l mt_02">Vlastní váha</span>
											</div>
										</div>
									</div>
									<div className="zero h1" />

									<div key="TYPEID21">
										<div className={`fl_l mr_2 ml_4 cr_p ${parseInt(typeId) === 1 ? 'button_disabled' : ''}`} onClick={(e) => setTypeId2Val(e, 1)}>
											<button className={`button check ${parseInt(typeId2) === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
											<span className="fl_l mt_02">Rychlost</span>
										</div>
									</div>
									<div className="zero h1" />
									<div key="TYPEID22">
										<div className={`fl_l mr_2 ml_4 cr_p ${parseInt(typeId) === 1 ? 'button_disabled' : ''}`} onClick={(e) => setTypeId2Val(e, 2)}>
											<button className={`button check ${parseInt(typeId2) === 2 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
											<span className="fl_l mt_02">Výdrž</span>
										</div>
									</div>
									<div className="zero h05" />
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={loading} />
									<button
										className="button"
										onClick={() => {
											closeNew();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
