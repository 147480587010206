import React, {useState, useRef} from 'react';
import validateEmail from '../utils/validateEmail.js';
import FormText from '../components/FormText.js';
import post from '../utils/post.js';
import {Link} from 'react-router-dom';

export default function Forget() {
	const [username, setUsername] = useState('');
	const [usernameError, setUsernameError] = useState('');
	const [loading, setLoading] = useState(false);
	const [usrID, setUsrID] = useState(0);
	const [result, setResult] = useState('Zadejte email pro obnovu hesla');

	const usernameRef = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();

		setUsernameError('');

		let localError = '';

		if (!username) {
			localError = 'Vyplňte email';
			setUsernameError(localError);
			usernameRef.current.focus();
		} else if (!validateEmail(username)) {
			localError = 'Neplatný email';
			setUsernameError(localError);
			usernameRef.current.focus();
		}

		if (!localError) {
			setLoading(true);

			post('forget', {username})
				.then((res) => {
					if (res > 0) {
						setUsrID(res);
					} else {
						setUsrID(0);
						setResult('Email není registrován');
					}

					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	return (
		<div className="login">
			{loading ? <div className="loading" /> : ''}

			<div className="zero h15" />
			<img className="login_logo" alt="" />
			<div className="zero h15" />

			<div className="login_container">
				<div className="login_container_in">
					{usrID === 0 ? (
						<>
							<span>Zadej registrovaný email pro obnovu hesla</span>
							<div className="zero h2"></div>

							<form method="POST" onSubmit={handleSubmit}>
								<FormText type="text" label="Email" value={username} setValue={setUsername} ref={usernameRef} error={usernameError} />
								<div className="zero h1" />
								<input type="submit" name="button_login" value="Odeslat" className="button" disabled={loading} />
								<div className="zero h1" />
								<span className="login_result">{result}</span>
							</form>
						</>
					) : (
						<>
							<span>Na zadaný e-mail byl zaslán odkaz na obnovení hesla.</span>
							<div className="zero h2"></div>
						</>
					)}

					<div className="zero h2"></div>
					<Link to={'/'} key="lnk_login" className="lnk1">
						Zpět na přihlášení
					</Link>
					<div className="zero h0"></div>
				</div>
			</div>
		</div>
	);
}
