import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import FormSelect from '../components/FormSelect.js';
import ChartArea from '../components/ChartArea.js';
import Cookies from 'js-cookie';

export default function Stat({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataStat, setDataStat] = useState([]);
	const [dataGraph, setDataGraph] = useState([]);
	const [workoutId, setWorkoutId] = useState(-1);
	const [workoutName, setWorkoutName] = useState('Moje hmotnost');

	const workoutRef = useRef(null);

	useEffect(() => {
		getData(workoutId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = (workoutId) => {
		setLoading(true);

		post('getListStatistics', {WORKOUTID: workoutId})
			.then((res) => {
				setData(res.data.data);
				setDataStat(res.data.data_stats);
				setDataGraph(res.data.data_graph);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const setWokout = (val) => {
		setWorkoutId(val.ID);
		setWorkoutName(val.NAME);

		getData(val.ID);
	};

	let list = [];
	let oldRec = '';
	let oldRecDT = '';
	let total = 0;
	let totalS = '';

	dataStat?.forEach((item, index) => {
		list.push(
			<div key={'header_main' + item.ID}>
				{item.CRDT_D !== oldRec && oldRec !== '' && item.TYPE === 'W' ? (
					<div key={'header_' + item.ID}>
						<div className="list" key={'header_in_' + item.ID}>
							<div className="list_in lnheader">
								<div className="fl_l">
									<b>{oldRecDT}</b>
								</div>
								<div className="fl_r">
									{item.TYPE === 'W' ? (
										<>
											{item.TYPEID === 1 ? (
												<>
													<b>{totalS} kg</b>
												</>
											) : (
												''
											)}
										</>
									) : (
										''
									)}
								</div>
								<div className="zero h0" />
							</div>
						</div>
						<div className="zero h1" />
					</div>
				) : (
					''
				)}

				<div className="list" key={'item_' + item.ID}>
					<div className="list_in">
						<div className="fl_l">{item.CRDTS}</div>
						<div className="fl_r">
							{item.TYPE === 'W' ? (
								<>
									{item.TYPEID === 1 ? (
										<>
											{item.CNT} x {item.WEIGHT} kg
										</>
									) : (
										<>{item.TIMESECS}</>
									)}
								</>
							) : (
								<>{item.MYWEIGHT} kg</>
							)}
						</div>
						<div className="zero h0" />
					</div>
				</div>
			</div>
		);

		if (item.CRDT_D !== oldRec && oldRec !== '' && item.TYPE === 'W') {
			total = 0;
		}

		oldRec = item.CRDT_D;
		oldRecDT = item.CRDT_DS;
		total += item.CNT * item.WEIGHT;
		totalS = total.toLocaleString();
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					<FormSelect type="text" label="Vyber cvik" value={workoutId} valueLabel={workoutName} setValue={setWokout} options={data} showNone={false} ref={workoutRef} error="" />

					{dataGraph?.data?.length > 0 ? (
						<div style={{height: '20rem'}}>
							<ChartArea data={dataGraph.data} labels={dataGraph.labels} color={dataGraph.color} linesColor={Cookies.get('progressgym_theme') ? '#555555' : '#dddddd'} />
						</div>
					) : (
						''
					)}
					<div className="zero h0" />

					{list}
				</div>
			</div>
		</>
	);
}
