import React, {useState, useEffect} from 'react';

export default function ExercisePlan({showPlan, closePlan, data, updatePlan}) {
	const [dataNew, setDataNew] = useState([]);

	useEffect(() => {
		setDataNew(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showPlan]);

	const handleSubmit = (e) => {
		e.preventDefault();
		closePlan();
		updatePlan(dataNew);
	};

	const selWorkout = (e, rec) => {
		e.preventDefault();

		setDataNew((prevRows) => {
			const updatedRows = dataNew.map((item, idex) => {
				if (item.ID === rec.ID) {
					item.ISCHECKED = item.ISCHECKED > 0 ? 0 : 1;
				}

				return item;
			});

			return updatedRows;
		});
	};

	let list = [];
	let oldRec = 0;

	dataNew?.forEach((item, index) => {
		list.push(
			<div key={'header_main' + item.ID}>
				{item.MUSCLEID !== oldRec ? (
					<div key={'header_' + item.ID}>
						{oldRec !== 0 ? <div className="zero h1" /> : ''}
						<div className="list" key={'header_in_' + item.ID} style={{backgroundColor: item.COLOR, background: 'linear-gradient(to right, ' + item.COLOR + ' 0%, #555555 50%)'}}>
							<div className="list_in lnheader">
								<div className="fl_l">
									<b>{item.MUSCLE_NAME}</b>
								</div>
								<div className="zero h0" />
							</div>
						</div>
					</div>
				) : (
					''
				)}

				<div className="list" key={'item_' + item.ID}>
					<div className="list_in">
						<div className="fl_l">
							<div className="fl_l cr_p" onClick={(e) => selWorkout(e, item)}>
								<button className={`button check ${item.ISCHECKED > 0 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
								<span className="fl_l ml_05 mt_03">{item.NAME}</span>
							</div>
						</div>
						<div className="zero h0" />
					</div>
				</div>
			</div>
		);

		oldRec = item.MUSCLEID;
	});

	return (
		<>
			{showPlan ? (
				<div className="popup">
					<div className="popup_content">
						<div className="popup_content_in_head">
							<div className="popup_content_in_head_in">Plán</div>
							<img
								alt=""
								className="popup_content_in_head_in-close"
								onClick={() => {
									closePlan();
								}}
							/>
						</div>
						<div className="popup_content_in_body">
							<div className="popup_content_in_body_in">
								<form
									method="POST"
									onSubmit={() => {
										return false;
									}}
								>
									{list}
								</form>
								<div className="zero h0" />
							</div>
						</div>
						<div className="popup_content_in_foot">
							<div className="popup_content_in_foot_in">
								<input type="button" name="button_save" value="Uložit" className="button green mr_2" onClick={handleSubmit} />
								<button
									className="button"
									onClick={() => {
										closePlan();
									}}
								>
									Zpět
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
