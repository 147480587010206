import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import MusclegroupNew from '../components/MusclegroupNew.js';
import MusclegroupEdit from '../components/MusclegroupEdit.js';
import ChartArea from '../components/ChartArea.js';
import Cookies from 'js-cookie';

export default function Musclegroup({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [showNew, setShowNew] = useState(false);
	const [showEdit, setShowEdit] = useState(false);

	useEffect(() => {
		getData('');
	}, []);

	const getData = (search) => {
		setLoading(true);

		post('getListMusclegroup', {search: search})
			.then((res) => {
				setData(res.data.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleNew = () => {
		setShowNew(true);
	};

	const closeNew = () => {
		setShowNew(false);
	};

	const updateMusclegroupRowNew = (row) => {
		setData([row, ...data]);
	};

	const handleEdit = (rec) => {
		setShowEdit(true);
		setDataDetail(rec);
	};

	const closeEdit = () => {
		setShowEdit(false);
	};

	const updateMusclegroupRowEdit = (row) => {
		const newData = data.map((item) => {
			if (item.ID === row.ID) {
				return row;
			}

			return item;
		});

		setData(newData);
	};

	const updateMusclegroupRowDelete = (row) => {
		const newData = data.filter((item) => {
			return item.ID !== row;
		});

		setData(newData);
	};

	const list = [];

	data.forEach((item, index) => {
		list.push(
			<div key={'item_' + index}>
				<div className="list" style={{backgroundColor: item.COLOR, background: 'linear-gradient(to right, ' + item.COLOR + ' 0%, #555555 50%)'}}>
					<div className="list_in lnheader">
						<div className="fl_l">
							<img className={`icon1 edit3 fl_l cr_p mr_1`} onClick={() => handleEdit(item)} alt="" />
						</div>
						<div className="fl_l">{item.NAME}</div>
						<div className="zero h0" />
					</div>
				</div>
				<div className="zero h0" />

				<div>
					<ChartArea data={item.data_graph.data} labels={item.data_graph.labels} color={item.COLOR} linesColor={Cookies.get('progressgym_theme') ? '#555555' : '#dddddd'} />
				</div>
				<div className="zero h0" />
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showNew handleNew={handleNew} />
				<MusclegroupNew showNew={showNew} closeNew={closeNew} updateMusclegroupRowNew={updateMusclegroupRowNew} />
				<MusclegroupEdit showEdit={showEdit} closeEdit={closeEdit} updateMusclegroupRowEdit={updateMusclegroupRowEdit} updateMusclegroupRowDelete={updateMusclegroupRowDelete} data={dataDetail} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>{list}</div>
			</div>
		</>
	);
}
