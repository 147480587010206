import React, {memo} from 'react';
import ReactApexChart from 'react-apexcharts';

function ChartArea({data, labels, color, linesColor = '#bbbbbb'}) {
	var cs = {
		name: 'cs',
		options: {
			months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
			shortMonths: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'],
			days: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
			shortDays: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
			toolbar: {
				exportToSVG: 'Stáhnout SVG',
				exportToPNG: 'Stáhnout PNG',
				exportToCSV: 'Stáhnout CSV',
				menu: 'Menu',
				selection: 'Vybrat',
				selectionZoom: 'Zoom: Vybrat',
				zoomIn: 'Zoom: Přiblížit',
				zoomOut: 'Zoom: Oddálit',
				pan: 'Přesouvat',
				reset: 'Resetovat',
			},
		},
	};

	let gr = {
		series: [{name: 'Hodnota', data: data}],
		options: {
			chart: {width: '100%', height: '100%', offsetY: -15, offsetX: -5, locales: [cs], defaultLocale: 'cs', type: 'area', zoom: {enabled: false}, stacked: false, toolbar: {show: false}},
			colors: [color],
			dataLabels: {enabled: false},
			tooltip: {theme: true, fillSeriesColor: false, color: linesColor},
			stroke: {curve: 'smooth', lineCap: 'round', width: 2},
			title: {text: '', align: 'left'},
			subtitle: {text: '', align: 'left'},
			labels: labels,
			legend: {horizontalAlign: 'left'},
			xaxis: {
				labels: {
					formatter: function (value) {
						let date = new Date(value);
						return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
					},
					show: false,
					rotate: 0,
					style: {fontSize: '1rem'},
				},
				axisTicks: {
					show: false,
					tickAmount: 6,
				},
			},
			yaxis: {labels: {show: true, rotate: 0, style: {fontSize: '1rem', colors: [linesColor]}}},
			grid: {
				show: true,

				position: 'back',
				borderColor: linesColor,
				strokeDashArray: 0,
				xaxis: {lines: {show: false}},
				yaxis: {lines: {show: true}},
			},
		},
	};

	return <ReactApexChart options={gr.options} series={gr.series} type="area" width="100%" height="100%" />;
}

export default memo(ChartArea);
