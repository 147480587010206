import React, {memo} from 'react';
import ReactApexChart from 'react-apexcharts';

function ChartArea({data1, data2, labels, linesColor = '#bbbbbb', selectedColumn, stepSize = 0}) {
	var cs = {
		name: 'cs',
		options: {
			months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
			shortMonths: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'],
			days: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
			shortDays: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
			toolbar: {
				exportToSVG: 'Stáhnout SVG',
				exportToPNG: 'Stáhnout PNG',
				exportToCSV: 'Stáhnout CSV',
				menu: 'Menu',
				selection: 'Vybrat',
				selectionZoom: 'Zoom: Vybrat',
				zoomIn: 'Zoom: Přiblížit',
				zoomOut: 'Zoom: Oddálit',
				pan: 'Přesouvat',
				reset: 'Resetovat',
			},
		},
	};

	let gr = {
		series: [
			{name: 'Příjmy', data: data1, typeV: 1},
			{name: 'Výdaje', data: data2, typeV: 2},
		],
		options: {
			chart: {
				width: '100%',
				height: '100%',
				offsetY: -15,
				offsetX: -5,
				locales: [cs],
				defaultLocale: 'cs',
				type: 'bar',
				zoom: {enabled: false},
				stacked: false,
				toolbar: {show: false},
				events: {
					dataPointSelection: function (event, chartContext, config) {
						let selectedDataIndex = config.dataPointIndex;
						let selectedSeriesIndex = config.seriesIndex;
						let selectedYValue = config.w.config.series[selectedSeriesIndex].data[selectedDataIndex];
						let selectedType = config.w.config.series[selectedSeriesIndex].typeV;
						let selectedLabel = config.w.globals.labels[selectedDataIndex];

						let date = new Date(selectedLabel);
						let selectedLabelS = date.getMonth() + 1 + '.' + date.getFullYear();
						selectedLabelS = '.' + selectedLabelS;

						selectedColumn(selectedType, selectedYValue, selectedLabelS);
					},
				},
			},
			plotOptions: {bar: {horizontal: false, borderRadius: 0, columnWidth: '75%', endingShape: 'rounded'}},
			dataLabels: {
				enabled: false,
				offsetY: -20,
				style: {
					fontSize: '0.9rem',
					colors: [linesColor],
				},
			},
			stroke: {curve: 'smooth', lineCap: 'round', width: 0},
			colors: ['#008844', '#e08800'],
			xaxis: {
				axisTicks: {show: false, tickAmount: 4},
				labels: {
					formatter: function (value) {
						let date = new Date(value);
						return date.getMonth() + 1 + '.' + date.getFullYear();
					},
					show: false,
					rotate: 0,
					style: {fontSize: '0.9rem'},
					offsetY: -4,
				},
			},
			yaxis: {
				stepSize: stepSize,
				labels: {
					formatter: function (value) {
						return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' Kč';
					},
					show: true,
					rotate: 0,
					style: {fontSize: '1rem', colors: [linesColor]},
				},
			},
			fill: {opacity: 1},
			tooltip: {theme: true, fillSeriesColor: false, color: linesColor},
			title: {text: '', align: 'left'},
			subtitle: {text: '', align: 'left'},
			labels: labels,
			legend: {show: false, labels: {colors: linesColor, useSeriesColors: false}},
			grid: {
				show: true,
				position: 'back',
				borderColor: linesColor,
				strokeDashArray: 0,
				xaxis: {lines: {show: false}},
				yaxis: {lines: {show: true}},
			},
		},
	};

	return <ReactApexChart options={gr.options} series={gr.series} type="bar" width="100%" height="100%" />;
}

export default memo(ChartArea);
