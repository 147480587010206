import React, {useState, useEffect} from 'react';

export default function Search({showSearch = false, searchValue = '', onChangeSearch, showNew = false, handleNew, children}) {
	const [timerId, setTimerId] = useState(null);
	const [value, setValue] = useState('');
	const [lastSearchValue, setLastSearchValue] = useState('');

	useEffect(() => {
		setValue(searchValue);
		setLastSearchValue(searchValue);
	}, [searchValue]);

	const goSearch = (e) => {
		onChangeSearch(e);
		setLastSearchValue(e);
	};

	const handleChange = (e) => {
		setValue(e.target.value);

		if (timerId) {
			clearTimeout(timerId);
		}

		const newTimerId = setTimeout(() => {
			goSearch(e.target.value);
		}, 1000);

		setTimerId(newTimerId);
	};

	const cancelSearch = (e) => {
		if (timerId) {
			clearTimeout(timerId);
		}

		if (lastSearchValue !== '') {
			goSearch('');
		}

		setValue('');
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			if (timerId) {
				clearTimeout(timerId);
			}

			goSearch(value);
		}
	};

	return (
		<div className="top">
			{showSearch ? (
				<>
					<div className="fl_l pos_r">
						<input className="input-text-search fl_l mr_1" type="text" placeholder="Vyhledat" value={value} onChange={(e) => handleChange(e)} onKeyDown={(e) => handleKeyDown(e)} />
						{value !== '' ? <span className="input-text-search-del" onClick={(e) => cancelSearch(e)}></span> : ''}
					</div>
				</>
			) : (
				''
			)}

			{children}

			{showNew ? (
				<button className="button small fl_r" onClick={() => handleNew()}>
					Nový
				</button>
			) : (
				''
			)}
		</div>
	);
}
