import React from 'react';
import AppHeader from '../components/AppHeader.js';
import clipboard from '../utils/clipboard.js';

export default function Pay({usrInfo, timerCnt}) {
	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={false} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					<div className="">
						<img className={`pay_qr cr_p`} onClick={() => clipboard('2602743724/2010')} alt="" />
						<div className="zero h1" />
						<div className="cr_p" onClick={() => clipboard('2602743724/2010')}>
							ČÚ: 2602743724/2010
						</div>
						<div className="zero h1" />
						<input type="button" className="button" value="Zkopírovat číslo účtu" onClick={() => clipboard('2602743724/2010')} />
						<div className="zero h2" />

						<div className="cr_p" onClick={() => clipboard('CZ5620100000002602743724')}>
							IBAN: CZ5620100000002602743724
						</div>
						<div className="zero h1" />
						<input type="button" className="button" value="Zkopírovat IBAN" onClick={() => clipboard('CZ5620100000002602743724')} />
						<div className="zero h2" />

						<div>Při platbě zadejte částku dle ceníku níže. Délka členství vám bude prodloužena podle zaslané částky ode dne přijetí platby.</div>
						<div className="zero h2" />
						<div>Pokud možno tak použijte okamžitou platbu. Jestli platební příkaz požaduje jméno příjemce tak zadejte: Peter Jalowiczor.</div>
						<div className="zero h2" />
						<div>
							<b>C &nbsp;E &nbsp;N &nbsp;Í &nbsp;K</b>
						</div>
						<div className="zero h1" />
						<div className="list" style={{width: '50%', margin: 'auto'}}>
							<div className="list_in">
								<b>
									<span className="fl_l">Vstup</span>
									<span className="fl_r">Cena</span>
								</b>
								<div className="zero h0" />
							</div>
						</div>
						<div className="zero h05" />
						<div className="list" style={{width: '50%', margin: 'auto'}}>
							<div className="list_in">
								<span className="fl_l">Jednorázový</span>
								<span className="fl_r">{usrInfo.ENTRY_PRICE_ONE},-</span>
								<div className="zero h1" />
								<span className="fl_l">1 měsíc</span>
								<span className="fl_r">{usrInfo.ENTRY_PRICE_MONTH1},-</span>
								<div className="zero h1" />
								<span className="fl_l">2 měsíce</span>
								<span className="fl_r">{usrInfo.ENTRY_PRICE_MONTH2},-</span>
								<div className="zero h1" />
								<span className="fl_l">3 měsíce</span>
								<span className="fl_r">{usrInfo.ENTRY_PRICE_MONTH3},-</span>
								<div className="zero h0" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
