import React, {useState, useEffect} from 'react';
import ConfirmPopup from './ConfirmPopup.js';

export default function HomeRes({showRes, closeRes, data, dataHours, updateReservations, deleteReservations, usrInfo}) {
	const [lng, setLng] = useState(90);
	const [addPerson, setAddPerson] = useState(0);
	const [lockType, setLockType] = useState(0);
	const [errorMsg, setErrorMsg] = useState('');
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);
	const [dtFrom, setDtFrom] = useState('');
	const [tmFrom, setTmFrom] = useState('');
	const [tmTo, setTmTo] = useState('');

	useEffect(() => {
		setLng(data.lng || 90);
		setAddPerson(data.addPerson || 0);
		setLockType(data.lock || 0);
		setDtFrom(data.dt);
		setTmFrom(data.tm);

		let dt = new Date(data.dt);
		dt.setMinutes(dt.getMinutes() + parseInt(data.lng));
		setTmTo(dt.toTimeString().substring(0, 5));

		setErrorMsg('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showRes]);

	const handleSubmit = (e) => {
		e.preventDefault();

		let canAddMax = true;
		let canAddSame = true;
		let canLocked = true;

		setErrorMsg('');

		if (parseInt(data.id) === 0) {
			dataHours.forEach((item, index) => {
				if (new Date(item.DT1).getTime() >= new Date(dtFrom).getTime() && new Date(item.DT1).getTime() <= new Date(dtFrom).getTime() + (lng - 30) * 60 * 1000) {
					if (item.USERS_CNT + addPerson + 1 > data.usr_max) {
						canAddMax = false;
					}

					item.USERS.forEach((item2, index2) => {
						if (parseInt(item2.USRID) === parseInt(usrInfo.usrId)) {
							canAddSame = false;
						}
					});

					if (parseInt(item.LOCKED) === 1) {
						canLocked = false;
					}
				}
			});
		} else {
			dataHours.forEach((item, index) => {
				if (new Date(item.DT1).getTime() >= new Date(dtFrom).getTime() && new Date(item.DT1).getTime() <= new Date(dtFrom).getTime() + (lng - 30) * 60 * 1000) {
					let actCnt = parseInt(item.USERS_CNT) - parseInt(data.addPerson) + parseInt(addPerson);

					if (actCnt > data.usr_max) {
						canAddMax = false;
					}

					item.USERS.forEach((item2, index2) => {
						if (parseInt(item2.USRID) === usrInfo.usrId && parseInt(item2.ID) !== parseInt(data.id)) {
							canAddSame = false;
						}
					});

					if (parseInt(item.LOCKED) === 1 && parseInt(item.LOCKED_ID) !== parseInt(data.id)) {
						canLocked = false;
					}
				}
			});
		}

		if (!canAddMax) {
			setErrorMsg('Maximální počet rezervací ve zvolený čas je ' + data.usr_max + '. Prosím zvolte jiný čas.');
		} else {
			if (!canAddSame) {
				setErrorMsg('Ve zvolený čas již máte rezervaci.');
			} else {
				if (!canLocked) {
					setErrorMsg('Ve zvolený čas je zavřeno.');
				}
			}
		}

		if (canAddMax && canAddSame && canLocked) {
			closeRes();
			updateReservations(data.id, dtFrom, lng, addPerson, lockType);
		}
	};

	const handleConfirmPopup = (e) => {
		e.preventDefault();
		setShowConfirmPopup(true);
	};

	const closeConfirmPopup = () => {
		setShowConfirmPopup(false);
	};

	const deleteConfirmPopup = () => {
		if (data.id > 0) {
			closeConfirmPopup();
			closeRes();
			deleteReservations(data.id, lockType);
		}
	};

	const setLngVal = (e, lng) => {
		e.preventDefault();
		setLng(lng);

		let dt = new Date(dtFrom);
		dt.setMinutes(dt.getMinutes() + lng);
		setTmTo(dt.toTimeString().substring(0, 5));
	};

	const setAddPersonVal = (e, addPerson) => {
		e.preventDefault();

		if (parseInt(data.lock) === 0 && parseInt(lockType) === 0) {
			setAddPerson((oldValue) => {
				if (addPerson === oldValue) {
					return 0;
				} else {
					return addPerson;
				}
			});
		}
	};

	const setLockTypeVal = (e, lockType) => {
		e.preventDefault();

		setLockType((oldValue) => {
			if (parseInt(lockType) === parseInt(oldValue) && parseInt(data.lock) === 0) {
				return 0;
			} else {
				setAddPerson(0);
				return lockType;
			}
		});
	};

	function setDTTM(val) {
		let year = val.getFullYear();
		let month = (val.getMonth() + 1).toString().padStart(2, '0');
		let day = val.getDate().toString().padStart(2, '0');
		let hour = val.getHours().toString().padStart(2, '0');
		let minute = val.getMinutes().toString().padStart(2, '0');
		let formattedDate = `${year}-${month}-${day} ${hour}:${minute}:00`;

		setDtFrom(formattedDate);
		setTmFrom(val.toTimeString().substring(0, 5));

		let dt = new Date(formattedDate);
		dt.setMinutes(dt.getMinutes() + parseInt(lng));
		setTmTo(dt.toTimeString().substring(0, 5));
	}

	const prevHalf = () => {
		let act = new Date(dtFrom);
		let today = new Date(dtFrom);
		today.setHours(0, 30, 0, 0);

		if (act.getTime() < today.getTime()) {
			return;
		}

		let dt = new Date(dtFrom);
		dt.setMinutes(dt.getMinutes() - 30);

		setDTTM(dt);
	};

	const nextHalf = () => {
		let act = new Date(dtFrom);
		let today = new Date(dtFrom);
		today.setHours(23, 0, 0, 0);

		if (act.getTime() > today.getTime()) {
			return;
		}

		let dt = new Date(dtFrom);
		dt.setMinutes(dt.getMinutes() + 30);

		setDTTM(dt);
	};

	let lngs = [];

	for (let i = 30; i <= 240; i = i + 30) {
		let hour = Math.floor(i / 60);
		let min = i - hour * 60;
		if (min === 0) {
			min = '';
		} else {
			min = '.5';
		}

		lngs.push(
			<div key={`lngs_` + i}>
				<div className="fl_l mr_2 cr_p" onClick={(e) => setLngVal(e, i)}>
					<button className={`button check ${parseInt(lng) === i ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
					<span className="fl_l mt_02">
						{hour}
						{min} h
					</span>
				</div>
				<div className="zero h05" />
			</div>
		);
	}

	let addpersons = [];

	for (let i = 1; i <= 4; i++) {
		addpersons.push(
			<div key={`addpersons_` + i}>
				<div className="fl_l mr_2 cr_p" onClick={(e) => setAddPersonVal(e, i)}>
					<button className={`button check ${parseInt(addPerson) === i ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
					<span className="fl_l mt_02">+{i}x doprovod</span>
				</div>
				<div className="zero h05" />
			</div>
		);
	}

	let lockTypes = [];

	for (let i = 1; i <= 2; i++) {
		if (parseInt(usrInfo.usrId) === 1 || parseInt(usrInfo.usrId) === 4 || (parseInt(usrInfo.usrId) === 18 && i === 1)) {
			lockTypes.push(
				<div key={`addpersons_` + i}>
					<div className="fl_l mr_2 cr_p" onClick={(e) => setLockTypeVal(e, i)}>
						<button className={`button check ${parseInt(lockType) === i ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
						<span className="fl_l mt_02">Zavřeno {i === 1 ? ' - lekce' : ''}</span>
					</div>
					<div className="zero h05" />
				</div>
			);
		}
	}

	return (
		<>
			<ConfirmPopup showConfirmPopup={showConfirmPopup} showButtonDelete deleteConfirmPopup={deleteConfirmPopup} closeConfirmPopup={closeConfirmPopup} infoHeader="Smazání rezervace" infoMain="Opravdu chceš smazat tuto rezervaci?" />

			{showRes ? (
				<div className="popup">
					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">{data.id > 0 ? 'Editace' : 'Nová'} rezervace:</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeRes();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in">
									<div className="zero h1" />
									<div className="fl_l">
										<span className="fl_l mt_02">Rezervace od&nbsp;</span>
										<span className="fl_l txt_l fnt_yellow mt_02">{tmFrom}</span>
										<span className="fl_l mt_02">&nbsp;do&nbsp;</span>
										<span className="fl_l txt_l fnt_yellow mr_1 mt_02" style={{width: '4.8rem'}}>
											{tmTo}
										</span>

										{tmFrom !== '00:00' ? (
											<div className="fl_l txt_c mr_1" style={{width: '3rem'}} onClick={() => prevHalf()}>
												<img className="icon_left cr_p" alt="" />
											</div>
										) : (
											<div className="fl_l txt_c mr_1" style={{width: '3rem'}}>
												<img className="icon_left disabled" alt="" />
											</div>
										)}

										{tmFrom !== '23:30' ? (
											<div className="fl_l txt_c mr_1" style={{width: '3rem'}} onClick={() => nextHalf()}>
												<img className="icon_right cr_p" alt="" />
											</div>
										) : (
											<div className="fl_l txt_c mr_1" style={{width: '3rem'}}>
												<img className="icon_right disabled" alt="" />
											</div>
										)}
									</div>
									<div className="zero h2" />

									{lngs}
									<div className="zero h2" />

									{addpersons}

									{parseInt(data.id) === 0 || (data.id > 0 && data.lock > 0) ? (
										<>
											{lockTypes.length > 0 ? <div className="zero h2" /> : ''}
											{lockTypes}
											{lockTypes.length > 0 ? <div className="zero h05" /> : ''}
										</>
									) : (
										''
									)}

									<span className="fnt_orange">{errorMsg}&nbsp;</span>
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={false} />
									{data.id > 0 ? (
										<button
											className="button red mr_2"
											onClick={(e) => {
												handleConfirmPopup(e);
											}}
										>
											Smazat
										</button>
									) : (
										''
									)}
									<button
										className="button"
										onClick={() => {
											closeRes();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
