import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import FormText from './FormText.js';
import clipboard from '../utils/clipboard.js';
import $ from 'jquery';
import ConfirmPopup from './ConfirmPopup.js';

export default function RezervationRecsEdit({showEdit, closeEdit, updateRezervationRecsRowEdit, updateRezervationRecsRowDelete, data}) {
	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState('');
	const [lng, setLng] = useState('');
	const [dateError, setDateError] = useState('');
	const [lngError, setLngError] = useState('');
	const [addPerson, setAddPerson] = useState('');
	const [addPersonError, setAddPersonError] = useState('');
	const [payedAddPerson, setPayedAddPerson] = useState(0);

	const [showConfirmPopup, setShowConfirmPopup] = useState(false);

	const dateRef = useRef(null);
	const lngRef = useRef(null);
	const addPersonRef = useRef(null);

	useEffect(() => {
		setDate('');
		setLng('');
		setAddPerson('');
	}, [showEdit]);

	useEffect(() => {
		setDate(data.RESERVATIONDT || '');
		setLng(data.RESERVATIONLNG || '');
		setAddPerson(data.ADD_PERSON || '');
		setPayedAddPerson(data.ADD_PERSON_PAYED || 0);

		setDateError('');
		setLngError('');
		setAddPersonError('');
	}, [data]);

	const handleSubmit = (e) => {
		e.preventDefault();

		setDateError('');
		setLngError('');
		setAddPersonError('');

		let localError = 0;

		if (!date) {
			localError = 1;
			setDateError('povinné');
			dateRef.current.focus();
		}

		if (!lng) {
			localError = 1;
			setLngError('povinné');
			lngRef.current.focus();
		}

		if (!addPerson) {
			localError = 1;
			setAddPersonError('povinné');
			addPersonRef.current.focus();
		}

		if (localError === 0) {
			setLoading(true);

			post('saveRezervationRecsOld', {ID: data.ID, RESERVATIONDT: date, RESERVATIONLNG: lng, ADD_PERSON: addPerson, ADD_PERSON_PAYED: payedAddPerson})
				.then((res) => {
					closeEdit();

					updateRezervationRecsRowEdit(res.data[0]);

					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const handleConfirmPopup = (e) => {
		e.preventDefault();
		setShowConfirmPopup(true);
	};

	const closeConfirmPopup = () => {
		setShowConfirmPopup(false);
	};

	const deleteConfirmPopup = () => {
		setLoading(true);

		post('deleteRezervationRecs', {ID: data.ID})
			.then((res) => {
				closeConfirmPopup();
				closeEdit();
				updateRezervationRecsRowDelete(data.ID);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const setPayed = (e) => {
		e.preventDefault();

		if (parseInt(payedAddPerson) === 0) {
			setPayedAddPerson(1);
		} else {
			setPayedAddPerson(0);
		}
	};

	return (
		<>
			<ConfirmPopup showConfirmPopup={showConfirmPopup} showButtonDelete deleteConfirmPopup={deleteConfirmPopup} closeConfirmPopup={closeConfirmPopup} infoHeader="Smazání záznamu" infoMain="Opravdu chceš smazat tento záznam?" />

			{showEdit ? (
				<div className="popup">
					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">
									Editace rezervace{' - '}
									<b>
										{data.LASTNAME} {data.FIRSTNAME} {data.NICKNAME !== '' ? `(${data.NICKNAME})` : ''}
									</b>
								</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeEdit();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in">
									<div className="fl_l" style={{width: '48%'}}>
										<FormText type="text" label="Datum a čas" value={date} setValue={setDate} ref={dateRef} error={dateError} />
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<FormText type="text" label="Délka (minut)" value={lng} setValue={setLng} ref={lngRef} error={lngError} />
									</div>
									<div className="zero h1" />

									<div className="fl_l" style={{width: '48%'}}>
										<FormText type="text" label="Doprovody" value={addPerson} setValue={setAddPerson} ref={addPersonRef} error={addPersonError} />
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<div className="fl_l mt_2 cr_p" onClick={(e) => setPayed(e)}>
											<button className={`button check ${parseInt(payedAddPerson) === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
											<span className="fl_l mt_02">Zaplaceno</span>
										</div>
									</div>
									<div className="zero h1" />
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={loading} />
									<button
										className="button red mr_2"
										onClick={(e) => {
											handleConfirmPopup(e);
										}}
									>
										Smazat
									</button>
									<button
										className="button"
										onClick={() => {
											closeEdit();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
