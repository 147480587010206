import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import FormText from './FormText.js';

export default function WorkoutOfficNew({showNew, closeNew, updateWorkoutOfficRowNew}) {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');

	const nameRef = useRef(null);

	useEffect(() => {
		setName('');
	}, [showNew]);

	const handleSubmit = (e) => {
		e.preventDefault();

		setNameError('');

		let localError = 0;

		if (!name) {
			localError = 1;
			setNameError('povinné');
			nameRef.current.focus();
		}

		if (localError === 0) {
			setLoading(true);

			post('saveWorkouOfficNew', {NAME: name})
				.then((res) => {
					closeNew();

					if (res.data > 0) {
						updateWorkoutOfficRowNew({ID: res.data, NAME: name});
					}

					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	return (
		<>
			{showNew ? (
				<div className="popup">
					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">Nový oficiální cvik</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeNew();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in">
									<FormText type="text" label="Název" value={name} setValue={setName} ref={nameRef} error={nameError} />
									<div className="zero h0" />
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={loading} />
									<button
										className="button"
										onClick={() => {
											closeNew();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
