import React from 'react';

const FormColor = React.forwardRef(({label, value = '999900', setValue, error, placeholder = '', maxLength}, ref) => {
	if (placeholder === '') {
		placeholder = label;
	}

	if (!maxLength) {
		maxLength = 100;
	}

	return (
		<div className="input-div">
			<div className="input-label">{label}</div>
			<div className="input-error">{error}</div>
			<input className="input-text color" type="color" placeholder={placeholder} ref={ref} value={value} onChange={(e) => setValue(e.target.value)} />
		</div>
	);
});

export default FormColor;
