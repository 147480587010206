import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import WorkoutNew from '../components/WorkoutNew.js';
import WorkoutEdit from '../components/WorkoutEdit.js';

export default function Workout({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataMuscles, setDataMuscles] = useState([]);
	const [dataWorkoutOffic, setDataWorkoutOffic] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [showNew, setShowNew] = useState(false);
	const [showEdit, setShowEdit] = useState(false);

	useEffect(() => {
		getData('');
	}, []);

	const getData = (search) => {
		setLoading(true);

		post('getListWorkout', {search: search})
			.then((res) => {
				setData(res.data.data);
				setDataMuscles(res.data.data_muscles);
				setDataWorkoutOffic(res.data.data_workoutoffic);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleNew = () => {
		setShowNew(true);
	};

	const closeNew = () => {
		setShowNew(false);
	};

	const updateWorkoutRowNew = (res) => {
		setData(res.data);
		setDataMuscles(res.data.data_muscles);
		setDataWorkoutOffic(res.data.data_workoutoffic);
	};

	const handleEdit = (rec) => {
		setShowEdit(true);
		setDataDetail(rec);
	};

	const closeEdit = () => {
		setShowEdit(false);
	};

	const updateWorkoutRowEdit = (res) => {
		setData(res.data);
		setDataMuscles(res.data.data_muscles);
		setDataWorkoutOffic(res.data.data_workoutoffic);
	};

	const updateWorkoutRowDelete = (res) => {
		setData(res.data);
		setDataMuscles(res.data.data_muscles);
		setDataWorkoutOffic(res.data.data_workoutoffic);
	};

	let list = [];
	let oldRec = 0;

	data.forEach((item, index) => {
		list.push(
			<div key={'header_main' + item.ID}>
				{item.MUSCLEID !== oldRec ? (
					<div key={'header_' + item.ID}>
						{oldRec !== 0 ? <div className="zero h1" /> : ''}
						<div className="list" key={'header_in_' + item.ID} style={{backgroundColor: item.COLOR, background: 'linear-gradient(to right, ' + item.COLOR + ' 0%, #555555 50%)'}}>
							<div className="list_in lnheader">
								<div className="fl_l">
									<b>{item.MUSCLE_NAME}</b>
								</div>
								<div className="zero h0" />
							</div>
						</div>
					</div>
				) : (
					''
				)}

				<div className="list" key={'item_' + item.ID}>
					<div className="list_in">
						<div className="fl_l">
							<img className={`icon1 edit fl_l cr_p mr_1`} onClick={() => handleEdit(item)} alt="" />
						</div>
						<div className="fl_l">{item.NAME}</div>
						<div className="zero h0" />
					</div>
				</div>
			</div>
		);

		oldRec = item.MUSCLEID;
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showNew handleNew={handleNew} />
				<WorkoutNew showNew={showNew} closeNew={closeNew} updateWorkoutRowNew={updateWorkoutRowNew} dataMuscles={dataMuscles} dataWorkoutOffic={dataWorkoutOffic} />
				<WorkoutEdit showEdit={showEdit} closeEdit={closeEdit} updateWorkoutRowEdit={updateWorkoutRowEdit} updateWorkoutRowDelete={updateWorkoutRowDelete} data={dataDetail} dataMuscles={dataMuscles} dataWorkoutOffic={dataWorkoutOffic} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>{list}</div>
			</div>
		</>
	);
}
