import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import WorkoutMaxNew from '../components/WorkoutMaxNew.js';
import WorkoutMaxEdit from '../components/WorkoutMaxEdit.js';

export default function WorkoutMax({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataMax, setDataMax] = useState([]);
	const [dataWorkout, setDataWorkout] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [showNew, setShowNew] = useState(false);
	const [showEdit, setShowEdit] = useState(false);

	useEffect(() => {
		getData('');
	}, []);

	const getData = (search) => {
		setLoading(true);

		post('getListWorkoutMax', {search: search})
			.then((res) => {
				setData(res.data.data);
				setDataMax(res.data.data_max);
				setDataWorkout(res.data.data_workout);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleNew = () => {
		setShowNew(true);
	};

	const closeNew = () => {
		setShowNew(false);
	};

	const updateWorkoutMaxRowNew = (row) => {
		setData(row.data);
		setDataMax(row.data_max);
		setDataWorkout(row.data_workout);
	};

	const handleEdit = (rec) => {
		setShowEdit(true);
		setDataDetail(rec);
	};

	const closeEdit = () => {
		setShowEdit(false);
	};

	const updateWorkoutMaxRowEdit = (row) => {
		setData(row.data);
		setDataMax(row.data_max);
		setDataWorkout(row.data_workout);
	};

	const updateWorkoutMaxRowDelete = (row) => {
		setData(row.data);
		setDataMax(row.data_max);
		setDataWorkout(row.data_workout);
	};

	let list = [];
	let oldRec = 0;

	data.forEach((item, index) => {
		list.push(
			<div key={'header_main' + item.ID}>
				{item.WORKOUTID !== oldRec ? (
					<div key={'header_' + item.ID}>
						{oldRec !== 0 ? <div className="zero h1" /> : ''}
						<div className="list" key={'header_in_' + item.ID} style={{backgroundColor: item.COLOR, background: 'linear-gradient(to right, ' + item.COLOR + ' 0%, #555555 50%)'}}>
							<div className="list_in lnheader">
								<div className="fl_l">
									<b>{item.NAME}</b>
								</div>
								<div className="fl_r">
									<b>
										{dataMax[item.WORKOUTID]['MAX']} {item.TYPEID === 1 ? 'kg' : ''}
									</b>
								</div>
								<div className="zero h0" />
							</div>
						</div>
					</div>
				) : (
					''
				)}

				<div className="list" key={'item_' + item.ID}>
					<div className="list_in">
						<div className="fl_l">
							<img className={`icon1 edit fl_l cr_p mr_1`} onClick={() => handleEdit(item)} alt="" />
						</div>
						<div className="fl_l">
							{item.CRDTS} - {item.AGE} let, {item.MYWEIGHT} kg
						</div>
						<div className="fl_r">
							{item.WEIGHT} {item.TYPEID === 1 ? 'kg' : ''}
						</div>
						<div className="zero h0" />
					</div>
				</div>
			</div>
		);

		oldRec = item.WORKOUTID;
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showNew handleNew={handleNew} />
				<WorkoutMaxNew showNew={showNew} closeNew={closeNew} updateWorkoutMaxRowNew={updateWorkoutMaxRowNew} dataWorkout={dataWorkout} />
				<WorkoutMaxEdit showEdit={showEdit} closeEdit={closeEdit} updateWorkoutMaxRowEdit={updateWorkoutMaxRowEdit} updateWorkoutMaxRowDelete={updateWorkoutMaxRowDelete} data={dataDetail} dataWorkout={dataWorkout} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>{list}</div>
			</div>
		</>
	);
}
