import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import FormText from './FormText.js';
import ConfirmPopup from './ConfirmPopup.js';

export default function WorkoutOfficEdit({showEdit, closeEdit, updateWorkoutOfficRowEdit, updateWorkoutOfficRowDelete, data}) {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);

	const nameRef = useRef(null);

	useEffect(() => {
		setName('');
	}, [showEdit]);

	useEffect(() => {
		setName(data.NAME || '');

		setNameError('');
	}, [data]);

	const handleSubmit = (e) => {
		e.preventDefault();

		setNameError('');

		let localError = 0;

		if (!name) {
			localError = 1;
			setNameError('povinné');
			nameRef.current.focus();
		}

		if (localError === 0) {
			setLoading(true);

			post('saveWorkoutOfficOld', {NAME: name, ID: data.ID})
				.then((res) => {
					closeEdit();

					if (res.data === 1) {
						updateWorkoutOfficRowEdit({ID: data.ID, NAME: name});
					}

					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const handleConfirmPopup = (e) => {
		e.preventDefault();
		setShowConfirmPopup(true);
	};

	const closeConfirmPopup = () => {
		setShowConfirmPopup(false);
	};

	const deleteConfirmPopup = () => {
		setLoading(true);

		post('deleteWorkoutOffic', {ID: data.ID})
			.then((res) => {
				closeConfirmPopup();
				closeEdit();
				updateWorkoutOfficRowDelete(data.ID);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	return (
		<>
			<ConfirmPopup showConfirmPopup={showConfirmPopup} showButtonDelete deleteConfirmPopup={deleteConfirmPopup} closeConfirmPopup={closeConfirmPopup} infoHeader="Smazání záznamu" infoMain="Opravdu chceš smazat tento záznam?" />

			{showEdit ? (
				<div className="popup">
					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">Editace oficiálního cviku</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeEdit();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in">
									<FormText type="text" label="Název" value={name} setValue={setName} ref={nameRef} error={nameError} />
									<div className="zero h1" />
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={loading} />
									<button
										className="button red mr_2"
										onClick={(e) => {
											handleConfirmPopup(e);
										}}
									>
										Smazat
									</button>
									<button
										className="button"
										onClick={() => {
											closeEdit();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
