import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import FormText from './FormText.js';
import FormDate from './FormDate.js';
import FormSelect from './FormSelect.js';
import FormTextArea from './FormTextArea.js';

export default function PaymentNew({showNew, closeNew, updateRow, dataUser, dataNew}) {
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState(0);
	const [amountError, setAmountError] = useState('');
	const [note, setNote] = useState('');
	const [typeId, setTypeId] = useState(1);
	const [paymentDT, setPaymendDT] = useState('');
	const [usrId, setUsrId] = useState(0);
	const [userName, setUserName] = useState('[None]');

	const amountRef = useRef();
	const noteRef = useRef();
	const paymendDTRef = useRef();
	const userNameRef = useRef();

	useEffect(() => {
		if (showNew) {
			setTypeId(1);
			setAmount(dataNew.amount || 0);
			setUsrId(dataNew.usrId || 0);
			setNote(dataNew.note || '');

			if (dataNew.usrId > 0) {
				let usr = dataUser.find((item) => parseInt(item.ID) === parseInt(dataNew.usrId));
				setUserName(usr.NAME || '[None]');
				//setNote('');
			} else {
				setUserName('[None]');
				setNote(dataNew.note || '');
			}

			let date = new Date();
			let day = date.getDate();
			let month = date.getMonth() + 1;
			let year = date.getFullYear();

			if (typeof dataNew.paymentDT !== 'undefined') {
				setPaymendDT(dataNew.paymentDT || '');
			} else {
				setPaymendDT(day + '.' + month + '.' + year);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showNew]);

	const handleSubmit = (e) => {
		e.preventDefault();

		setAmountError('');

		let localError = 0;

		if (!amount || amount === 0) {
			localError = 1;
			setAmountError('povinné');
			amountRef.current.focus();
		}

		if (localError === 0) {
			setLoading(true);

			post('savePaymentNew', {AMOUNT: amount, NOTE: note, TYPEID: typeId, PAYMENTDT: paymentDT, USRID: usrId})
				.then((res) => {
					updateRow(res.data);
					closeNew();
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const setType = (id) => {
		setTypeId(id);

		if (parseInt(id) === 2) {
			setUsrId(0);
			setUserName('[None]');
		}
	};

	const setMember = (val) => {
		setUsrId(val.ID);
		setUserName(val.NAME);
	};

	const copyToNote = (val) => {
		setNote(val);
	};

	return (
		<>
			{showNew ? (
				<div className="popup">
					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">Nová platba</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeNew();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in">
									<div className="fl_l" style={{width: '48%'}}>
										<div className="zero h2" />
										<div className="fl_l mr_2 cr_p" onClick={() => setType(1)}>
											<button className={`button check ${parseInt(typeId) === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
											<span className="fl_l mt_02">Příjmy</span>
										</div>
										<div className="fl_l cr_p" onClick={() => setType(2)}>
											<button className={`button check ${parseInt(typeId) === 2 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
											<span className="fl_l mt_02">Výdaje</span>
										</div>
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<FormDate label="Datum" maxLength="10" readOnly value={paymentDT} setValue={setPaymendDT} ref={paymendDTRef} error="" />
									</div>

									<div className="zero h0" />

									<div className="fl_l" style={{width: '48%'}}>
										{parseInt(typeId) === 1 ? <FormSelect type="text" label="Člen" value={usrId} valueLabel={userName} setValue={setMember} options={dataUser} ref={userNameRef} error="" /> : ''}
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<FormText type="text" label="Částka" value={amount} setValue={setAmount} ref={amountRef} error={amountError} />
									</div>

									<div className="zero h0" />
									<FormTextArea type="text" label="Poznámka" height="12rem" value={note} setValue={setNote} ref={noteRef} error="" />

									<div className="zero h4" />
									<span className="mr_2 lnk2 cr_p" onClick={() => copyToNote('Hotově')}>
										Hotově
									</span>
									<span className="mr_2 lnk2 cr_p" onClick={() => copyToNote('Hotově - občerstvení')}>
										Hotově - občerstvení
									</span>
									<div className="zero h2" />
									<span className="mr_2 lnk2 cr_p" onClick={() => copyToNote('Občerstvení')}>
										Občerstvení
									</span>
									<span className="mr_2 lnk2 cr_p" onClick={() => copyToNote('Doprovod')}>
										Doprovod
									</span>
									<div className="zero h4" />
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={loading} />
									<button
										className="button"
										onClick={() => {
											closeNew();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
