import React, {useState, useRef} from 'react';
import validateEmail from '../utils/validateEmail.js';
import validateRestorePassword from '../utils/validateRestorePassword.js';
import FormText from '../components/FormText.js';
import post from '../utils/post.js';
import {Link} from 'react-router-dom';

export default function Register() {
	const [username, setUsername] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [nickname, setNickname] = useState('');
	const [phone, setPhone] = useState('');
	const [prefix, setPrefix] = useState('420');
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [usernameError, setUsernameError] = useState('');
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [nicknameError, setNicknameError] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [prefixError, setPrefixError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [passwordError2, setPasswordError2] = useState('');
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState('');
	const [result2, setResult2] = useState(0);

	const usernameRef = useRef();
	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const nicknameRef = useRef();
	const phoneRef = useRef();
	const prefixRef = useRef();
	const passwordRef = useRef();
	const passwordRef2 = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();

		setUsernameError('');
		setFirstNameError('');
		setLastNameError('');
		setNicknameError('');
		setPhoneError('');
		setPrefixError('');
		setPasswordError('');
		setPasswordError2('');

		let localError = 0;

		if (!username) {
			localError = 1;
			setUsernameError('povinné');
			usernameRef.current.focus();
		} else if (!validateEmail(username)) {
			localError = 1;
			setUsernameError('Neplatný email');
			usernameRef.current.focus();
		}

		if (!firstName) {
			if (localError === 0) {
				firstNameRef.current.focus();
			}
			localError = 1;
			setFirstNameError('povinné');
		}

		if (!lastName) {
			if (localError === 0) {
				lastNameRef.current.focus();
			}
			localError = 1;
			setLastNameError('povinné');
		}

		if (!prefix) {
			if (localError === 0) {
				prefixRef.current.focus();
			}
			localError = 1;
			setPrefixError('*');
		}

		if (!phone || phone.length < 9) {
			if (localError === 0) {
				phoneRef.current.focus();
			}
			localError = 1;
			setPhoneError('*');
		}

		const sError = validateRestorePassword(password, password2);
		setPasswordError(sError.password);
		setPasswordError2(sError.password2);

		if (sError.password !== '') {
			if (localError === 0) {
				passwordRef.current.focus();
			}
			localError = 1;
		}

		if (sError.password2 !== '') {
			if (localError === 0) {
				passwordRef2.current.focus();
			}
			localError = 1;
		}

		if (localError === 0) {
			setLoading(true);

			post('register', {username, firstName, lastName, nickname, phone, prefix, password})
				.then((res) => {
					if (res === -1) {
						setResult('Došlo k neznámé chybě. Zkuste to později.');
					} else if (res === 0) {
						setResult('Tento email je už registrovaný. Zkuste jiný.');
					} else if (res === 1) {
						setResult2(1);
					}

					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	return (
		<div className="login">
			{loading ? <div className="loading" /> : ''}

			<div className="zero h15" />
			<img className="login_logo" alt="" />
			<div className="zero h15" />

			<div className="login_container">
				<div className="login_container_in">
					<span>Registrace</span>
					<div className="zero h15"></div>

					{result2 === 0 ? (
						<>
							<form method="POST" onSubmit={handleSubmit}>
								<FormText type="text" label="Email" value={username} setValue={setUsername} ref={usernameRef} error={usernameError} />

								<div className="fl_l" style={{width: '48%'}}>
									<FormText type="text" label="Jméno" value={firstName} setValue={setFirstName} ref={firstNameRef} error={firstNameError} />
								</div>
								<div className="fl_r" style={{width: '48%'}}>
									<FormText type="text" label="Příjmení" value={lastName} setValue={setLastName} ref={lastNameRef} error={lastNameError} />
								</div>

								<div className="fl_l" style={{width: '48%'}}>
									<FormText type="text" label="Přezdívka" value={nickname} setValue={setNickname} ref={nicknameRef} error={nicknameError} />
								</div>
								<div className="fl_r" style={{width: '48%'}}>
									<div className="fl_r" style={{width: '60%'}}>
										<FormText type="text" label="Telefon" placeholder="111222333" maxLength="9" value={phone} setValue={setPhone} ref={phoneRef} error={phoneError} />
									</div>
									<div className="fl_l" style={{width: '36%'}}>
										<FormText type="text" label="Předv." value={prefix} placeholder="420" maxLength="3" setValue={setPrefix} ref={prefixRef} error={prefixError} />
									</div>
								</div>

								<div className="fl_l" style={{width: '48%'}}>
									<FormText type="password" label="Heslo" value={password} setValue={setPassword} ref={passwordRef} error={passwordError} />
								</div>
								<div className="fl_r" style={{width: '48%'}}>
									<FormText type="password" label="Znovu hesla" value={password2} setValue={setPassword2} ref={passwordRef2} error={passwordError2} />
								</div>

								<div className="zero h1" />
								<input type="submit" name="button_login" value="Registrovat" className="button" disabled={loading} />
								<div className="zero h1" />
								<span className="login_result">{result}</span>
							</form>
						</>
					) : (
						<>
							<span>Registrace proběhla úspěšně. Na zadanou emailovou adresu byl zaslán odkaz na aktivaci účtu. Po aktivaci bude možné se přihlásit.</span>
							<div className="zero h2"></div>
						</>
					)}

					<div className="zero h2"></div>
					<Link to={'/'} key="lnk_login" className="lnk1">
						Zpět na přihlášení
					</Link>
					<div className="zero h0"></div>
				</div>
			</div>
		</div>
	);
}
