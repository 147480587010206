import React from 'react';

const FormTextArea = React.forwardRef(({type, label, value, setValue, error, placeholder = '', height = ''}, ref) => {
	if (placeholder === '') {
		placeholder = label;
	}

	return (
		<div className="input-div">
			<div className="input-label">{label}</div>
			<div className="input-error">{error}</div>
			<textarea className="input-textarea" style={{height: height !== '' ? height : '7rem'}} placeholder={placeholder} ref={ref} value={value} onChange={(e) => setValue(e.target.value)}>
				{value}
			</textarea>
		</div>
	);
});

export default FormTextArea;
