import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import clipboard from '../utils/clipboard.js';
import ConfirmPopup from '../components/ConfirmPopup.js';

export default function Keys({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [oldDoorKey, setOldDoorKey] = useState({});
	const [doorKey, setDoorKey] = useState({});
	const [doorKeyUpdated, setDoorKeyUpdated] = useState({});
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);

	useEffect(() => {
		getData('');
	}, []);

	const getData = (search) => {
		setLoading(true);

		post('getKeys', {search: search})
			.then((res) => {
				setData(res.data.data);
				setLoading(false);

				res.data.data.forEach((item, index) => {
					setOldDoorKey((prevDoorKey) => ({...prevDoorKey, ['LIST_KEYS_DOORKEY_' + item.ID]: item.DOORKEY}));
					setDoorKeyUpdated((prevDoorKeyUpdated) => ({...prevDoorKeyUpdated, ['LIST_KEYS_DOORKEY_' + item.ID]: parseInt(item.DOORKEY_UPDATED)}));
				});
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const saveKey = (id) => {
		if (doorKey['LIST_KEYS_DOORKEY_' + id] !== '') {
			setLoading(true);

			post('saveKey', {ID: id, DOORKEY: doorKey['LIST_KEYS_DOORKEY_' + id]})
				.then((res) => {
					setLoading(false);

					setOldDoorKey({
						...oldDoorKey,
						['LIST_KEYS_DOORKEY_' + id]: doorKey['LIST_KEYS_DOORKEY_' + id],
					});

					setDoorKeyUpdated({...doorKeyUpdated, ['LIST_KEYS_DOORKEY_' + id]: 1});
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const handleConfirmPopup = (e) => {
		e.preventDefault();
		setShowConfirmPopup(true);
	};

	const closeConfirmPopup = () => {
		setShowConfirmPopup(false);
	};

	const cofirmConfirmPopup = () => {
		setLoading(true);

		post('saveAllNotUpdated', {})
			.then((res) => {
				setLoading(false);
				setShowConfirmPopup(false);
				getData('');
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const list = data.map((item, index) => {
		let usrName = '';
		if (item.NICKNAME !== '') {
			usrName = `${item.LASTNAME} ${item.FIRSTNAME} (${item.NICKNAME})`;
		} else {
			usrName = `${item.LASTNAME} ${item.FIRSTNAME}`;
		}

		if (typeof doorKey['LIST_KEYS_DOORKEY_' + item.ID] === 'undefined') {
			doorKey['LIST_KEYS_DOORKEY_' + item.ID] = '';
		}

		return (
			<div key={'item_' + index}>
				<div className={`list ${doorKeyUpdated['LIST_KEYS_DOORKEY_' + item.ID] === 1 ? 'inactive' : ''}`}>
					<div className="list_in">
						<div className="fl_l">
							<img className={`icon1 member ${parseInt(item.CLUBMEMBER) === 1 ? 'active' : ''} fl_l mr_05 mt_07`} alt="" />
							<img className={`icon2 permanent ${parseInt(item.PERNAMENTMEMBER) === 1 ? 'active' : ''} fl_l mr_05 mt_05`} alt="" />
							<div className="fl_l">
								<span className="fl_l fnt_11" onClick={() => clipboard(usrName)}>
									{usrName}
								</span>
								<div className="zero h02" />
								<div className="fl_l fnt_1" onClick={() => clipboard(oldDoorKey['LIST_KEYS_DOORKEY_' + item.ID])}>
									{oldDoorKey['LIST_KEYS_DOORKEY_' + item.ID]}
								</div>
							</div>
						</div>

						<img className="icon1 ok1 fl_r cr_p ml_1 mt_07" onClick={() => saveKey(item.ID)} alt="" />

						<div className="fl_r">
							<div className="fl_r ml_1 mt_04" style={{width: '11rem'}}>
								<input
									className="input-text small"
									id={'LIST_KEYS_DOORKEY_' + item.ID}
									type="text"
									value={doorKey['LIST_KEYS_DOORKEY_' + item.ID] || ''}
									onChange={(e) =>
										setDoorKey({
											...doorKey,
											['LIST_KEYS_DOORKEY_' + item.ID]: e.target.value,
										})
									}
								/>
							</div>
						</div>

						<div className="zero h0" />
					</div>
				</div>
				<div className="zero h05" />
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<ConfirmPopup showConfirmPopup={showConfirmPopup} showButtonConfirm cofirmConfirmPopup={cofirmConfirmPopup} closeConfirmPopup={closeConfirmPopup} infoHeader="Smazání záznamu" infoMain="Opravdu chceš nastavit všechny záznamy jako neupdatované?" />
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					<input type="button" className="button red fl_l " value="Nastavit všechny jako neupdatovane" onClick={(e) => handleConfirmPopup(e)} />
					<div className="zero h2" />
					{list}
				</div>
			</div>
		</>
	);
}
