import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import FormSelect from './FormSelect.js';
import FormText from './FormText.js';

export default function WorkoutMaxNew({showNew, closeNew, updateWorkoutMaxRowNew, dataWorkout}) {
	const [loading, setLoading] = useState(false);
	const [workoutId, setWorkoutId] = useState(0);
	const [workoutName, setWorkoutName] = useState('[None]');
	const [workoutTypeId, setWorkoutTypeId] = useState(0);
	const [workoutError, setWorkoutError] = useState('');
	const [weight, setWeight] = useState(0);
	const [weightError, setWeightError] = useState('');
	const [mins, setMins] = useState(0);
	const [minsError, setMinsError] = useState('');
	const [secs, setSecs] = useState(0);
	const [secsError, setSecsError] = useState('');

	const workoutRef = useRef(null);
	const weightRef = useRef(null);
	const minsRef = useRef(null);
	const secsRef = useRef(null);

	useEffect(() => {
		setWorkoutId(0);
		setWorkoutTypeId(0);
		setWorkoutName('[None]');
		setWeight(0);
		setMins(0);
		setSecs(0);

		setWorkoutError('');
		setWeightError('');
		setMinsError('');
		setSecsError('');
	}, [showNew]);

	const setWokout = (val) => {
		setWorkoutId(val.ID);
		setWorkoutName(val.NAME);
		setWorkoutTypeId(val.TYPEID);

		setWeight(0);
		setMins(0);
		setSecs(0);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setWorkoutError('');
		setWeightError('');
		setMinsError('');
		setSecsError('');

		let localError = 0;

		if (parseInt(workoutId) === 0) {
			localError = 1;
			setWorkoutError('povinné');
			workoutRef.current.focus();
		}

		if (parseInt(workoutTypeId) === 1 && parseInt(weight) === 0) {
			localError = 1;
			setWeightError('povinné');
			weightRef.current.focus();
		}

		if (parseInt(workoutTypeId) === 2 && parseInt(mins) === 0 && parseInt(secs) === 0) {
			localError = 1;
			setSecsError('povinné');
			secsRef.current.focus();
		}

		if (localError === 0) {
			setLoading(true);

			post('saveWorkouMaxNew', {WORKOUTID: workoutId, WEIGHT: weight, MINS: mins, SECS: secs})
				.then((res) => {
					closeNew();

					updateWorkoutMaxRowNew(res.data);

					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	return (
		<>
			{showNew ? (
				<div className="popup">
					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">Nová maximálka</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeNew();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in" style={{height: '45rem'}}>
									<FormSelect type="text" label="Cvik" value={workoutId} valueLabel={workoutName} setValue={setWokout} options={dataWorkout} ref={workoutRef} error={workoutError} />
									<div className="zero h0" />

									{parseInt(workoutTypeId) === 1 ? <FormText type="text" label="Váha" value={weight} setValue={setWeight} ref={weightRef} error={weightError} /> : ''}

									{parseInt(workoutTypeId) === 2 ? (
										<>
											<div className="fl_l" style={{width: '48%'}}>
												<FormText type="text" label="Minuty" value={mins} setValue={setMins} ref={minsRef} error={minsError} />
											</div>
											<div className="fl_r" style={{width: '48%'}}>
												<FormText type="text" label="Sekundy" value={secs} setValue={setSecs} ref={secsRef} error={secsError} />
											</div>
										</>
									) : (
										''
									)}

									<div className="zero h0" />
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={loading} />
									<button
										className="button"
										onClick={() => {
											closeNew();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
