import React from 'react';
import clipboard from '../utils/clipboard.js';

export default function UserQR({showQR, closeQR, qrInfo}) {
	return (
		<>
			{showQR ? (
				<div className="popup">
					<div className="popup_content">
						<div className="popup_content_in_head">
							<div className="popup_content_in_head_in">
								Vizitka: {qrInfo?.data.LASTNAME} {qrInfo?.data.FIRSTNAME} {qrInfo?.data.NICKNAME !== '' ? '(' + qrInfo?.data.NICKNAME + ')' : ''}
							</div>
							<img
								alt=""
								className="popup_content_in_head_in-close"
								onClick={() => {
									closeQR(false);
								}}
							/>
						</div>
						<div className="popup_content_in_body">
							<div className="popup_content_in_body_in">
								<div className="qrcode cr_p" id="QRCODE" onClick={() => clipboard(qrInfo?.VCARD)} />
								<div className="zero h1" />
								<div className="" id="QRTXT" />
								<div className="zero h1" />
								<button
									className="button"
									onClick={() => {
										clipboard(qrInfo?.VCARD);
									}}
								>
									Zkopírovat do schránky
								</button>
								<div className="zero h1" />
							</div>
						</div>
						<div className="popup_content_in_foot">
							<div className="popup_content_in_foot_in">
								<button
									className="button"
									onClick={() => {
										closeQR(false);
									}}
								>
									Zpět
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
