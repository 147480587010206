import React, {useState, useEffect, useRef} from 'react';
import validateRestorePassword from '../utils/validateRestorePassword.js';
import FormText from '../components/FormText.js';
import post from '../utils/post.js';
import {Link} from 'react-router-dom';

export default function Restore() {
	const [checkCode, setcheckCode] = useState(0);
	const [email, setEmail] = useState('');
	const [result, setResult] = useState(0);
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [passwordError2, setPasswordError2] = useState('');

	const passwordRef = useRef();
	const passwordRef2 = useRef();

	var res_code = window.location.search.split('=')[1];

	useEffect(() => {
		if (res_code) {
			getValidRestoreCode();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getValidRestoreCode = () => {
		setLoading(true);

		post('restoreValidCode', {res_code})
			.then((res) => {
				if (res['result'] === 1) {
					setcheckCode(1);
					setEmail(res['resultEmail']);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const localError = validateRestorePassword(password, password2);

		setPasswordError(localError.password);
		setPasswordError2(localError.password2);

		if (!localError.password && !localError.password2) {
			setLoading(true);

			post('restore', {email, password, password2})
				.then((res) => {
					if (res['result'] === 1) {
						setResult(1);
					}
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	return (
		<div className="login">
			{loading ? <div className="loading" /> : ''}

			<div className="zero h15" />
			<img className="login_logo" alt="" />
			<div className="zero h15" />

			<div className="login_container">
				<div className="login_container_in">
					{checkCode === 1 ? (
						<>
							{result === 1 ? (
								<>
									<span>Heslo bylo změněno</span>
									<div className="zero h15"></div>
								</>
							) : (
								<>
									<span>Zadej nové heslo</span>
									<div className="zero h15"></div>

									<form method="POST" onSubmit={handleSubmit}>
										<FormText type="password" label="Heslo" value={password} setValue={setPassword} ref={passwordRef} error={passwordError} />
										<FormText type="password" label="Znovu hesla" value={password2} setValue={setPassword2} ref={passwordRef2} error={passwordError2} />
										<div className="zero h1" />
										<input type="submit" name="button_login" value="Odeslat" className="button" disabled={loading} />
									</form>
								</>
							)}
						</>
					) : (
						<>
							<span>Neplatný kód</span>
							<div className="zero h15"></div>
						</>
					)}

					<div className="zero h2"></div>
					<Link to={'/'} key="lnk_login" className="lnk1">
						Zpět na přihlášení
					</Link>
					<div className="zero h0"></div>
				</div>
			</div>
		</div>
	);
}
