import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import {Link} from 'react-router-dom';

export default function Activate() {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');

	var res_code = window.location.search.split('=')[1];

	useEffect(() => {
		if (res_code) {
			getValidRegistrationCode();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getValidRegistrationCode = () => {
		setLoading(true);

		post('activate', {res_code})
			.then((res) => {
				if (res !== '') {
					setEmail(res);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	return (
		<div className="login">
			{loading ? <div className="loading" /> : ''}

			<div className="zero h15" />
			<img className="login_logo" alt="" />
			<div className="zero h15" />

			<div className="login_container">
				<div className="login_container_in">
					{email === '' ? (
						<>
							<span>Aktivační kód neexistuje. Prosím proveďte registraci.</span>
							<div className="zero h15"></div>
						</>
					) : (
						<>
							<span>
								Účet <b>{email}</b> byl aktivován.
							</span>
							<div className="zero h15"></div>
						</>
					)}

					<div className="zero h2"></div>
					<Link to={'/'} key="lnk_login" className="lnk1">
						Zpět na přihlášení
					</Link>
					<div className="zero h0"></div>
				</div>
			</div>
		</div>
	);
}
