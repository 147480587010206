import React from 'react';
import AppHeader from '../components/AppHeader.js';

export default function Rule({usrInfo, timerCnt}) {
	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={false} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					<div className="">
						<div className="zero h1" />
						<div className="cr_p">INFO</div>
						<div className="zero h1" />

						<div className="list" style={{width: '100%', margin: 'auto'}}>
							<div className="list_in txt_l">
								<span className="fl_l">
									<b>1.</b> vstup na vlastní nebezpečí
								</span>
								<div className="zero h02" />
								<span className="fl_l">
									<b>2.</b> prostor je hlídaný kamerou se záznamem
								</span>
								<div className="zero h0" />
							</div>
						</div>

						<div className="zero h2" />
						<div className="cr_p">PRAVIDLA</div>
						<div className="zero h1" />

						<div className="list" style={{width: '100%', margin: 'auto'}}>
							<div className="list_in txt_l">
								<span className="fl_l">
									<b>1.</b> rezervuj si v apce termín před vstupem do fitka
								</span>
								<div className="zero h02" />
								<span className="fl_l">
									<b>2.</b> přezouvat (zákaz vstupu v botech ve kterých jste přišli z venku)
								</span>
								<div className="zero h02" />
								<span className="fl_l">
									<b>3.</b> zavírat vrata a okna, zamykat dveře při odchodu (pokud odcházíš jako poslední)
								</span>
								<div className="zero h02" />
								<span className="fl_l">
									<b>4.</b> všude vypnout světla (pokud odcházíš jako poslední)
								</span>
								<div className="zero h02" />
								<span className="fl_l">
									<b>5.</b> uklízet po sobě nářadí, činky, kotouče, nádobí, odpadky
								</span>
								<div className="zero h02" />
								<span className="fl_l">
									<b>6.</b> po použití sprchy vždy otevři okno a zavři dveře od koupelny
								</span>
								<div className="zero h02" />
								<span className="fl_l">
									<b>7.</b> nemanipulovat s topením
								</span>
								<div className="zero h02" />
								<span className="fl_l">
									<b>8.</b> parkovat pouze u fitka (co nejblíže k budově), rozhodně né u bytovek nad fitkem
								</span>
								<div className="zero h02" />
								<span className="fl_l">
									<b>9.</b> po 22. hodině dodržovat noční klid (zakázáno používat hlasitou hudbu, nebo jiný hlasitý zvuk)
								</span>
								<div className="zero h02" />
								<span className="fl_l">
									<b>10.</b> pokud si vezmeš drink, nebo něco jiného, vždy to musí být přídáno v e-shopu
								</span>
								<div className="zero h0" />
							</div>
						</div>

						<div className="zero h2" />
						<div className="cr_p">KONTAKT</div>
						<div className="zero h1" />

						<div className="list" style={{width: '100%', margin: 'auto'}}>
							<div className="list_in">
								<span className="fl_l">
									<b>Roman:</b>{' '}
									<a className="lnk1 cr_p" href="tel:+420605359072">
										+420 605 359 072
									</a>
								</span>
								<div className="zero h1" />
								<span className="fl_l">
									<b>Peter:</b>{' '}
									<a className="lnk1 cr_p" href="tel:+420775096383">
										+420 775 096 383
									</a>
								</span>
								<div className="zero h1" />
								<span className="fl_l">
									<b>Adresa:</b>{' '}
									<a className="lnk1 cr_p" href="https://mapy.cz/s/dejofonoko" target="_blank" rel="noopener noreferrer">
										Návsí 1160
									</a>
								</span>
								<div className="zero h0" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
