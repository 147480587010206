import React, {useState, useEffect} from 'react';

export default function Calendar({show, close, defaultValue, returnValue, dataEvents = []}) {
	const [value, setValue] = useState('');
	const [showMonth, setShowMonth] = useState(false);
	const [showYear, setShowYear] = useState(false);

	useEffect(() => {
		var newVal = defaultValue;

		if (defaultValue === '' || defaultValue === '0.0.0' || defaultValue === '0.0.0000' || defaultValue === '0.0.0000 0:0:0' || defaultValue === '0.0.0000 0:0:0.0') {
			newVal = new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear();
		}

		setValue(newVal);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	const monthNames = [
		{index: 1, name: 'Leden'},
		{index: 2, name: 'Únor'},
		{index: 3, name: 'Březen'},
		{index: 4, name: 'Duben'},
		{index: 5, name: 'Květen'},
		{index: 6, name: 'Červen'},
		{index: 7, name: 'Červenec'},
		{index: 8, name: 'Srpen'},
		{index: 9, name: 'Září'},
		{index: 10, name: 'Říjen'},
		{index: 11, name: 'Listopad'},
		{index: 12, name: 'Prosinec'},
	];

	const getMonthName = (index) => {
		let name = '';

		monthNames.forEach((item) => {
			if (parseInt(item.index) === parseInt(index)) {
				name = item.name;
			}
		});

		return name;
	};

	const getDayFromValue = (val) => {
		let day = '';

		if (val !== '') {
			let date = val.split('.');

			day = date[0];
		}

		if (day.length === 1) {
			day = '0' + day;
		}

		return day;
	};

	const getMonthFromValue = (val) => {
		let month = '';

		if (val !== '') {
			let date = val.split('.');

			month = date[1];
		}

		if (month.length === 1) {
			month = '0' + month;
		}

		return month;
	};

	const getMonthNameFromValue = (val) => {
		let month = '';

		if (val !== '') {
			let date = val.split('.');

			month = getMonthName(date[1]);
		}

		return month;
	};

	const getYearFromValue = (val) => {
		let year = '';

		if (val !== '') {
			let date = val.split('.');

			year = date[2];
		}

		return year;
	};

	const setNextMonth = () => {
		let day = '01';
		let month = getMonthFromValue(value);
		let year = getYearFromValue(value);

		let date = new Date(year, month, day);

		date.setMonth(date.getMonth() + 1);

		let newDay = date.getDate();
		let newMonth = date.getMonth();
		let newYear = date.getFullYear();

		if (newMonth === 0) {
			newMonth = 12;
			newYear = newYear - 1;
		}

		setValue(newDay + '.' + newMonth + '.' + newYear);
	};

	const setPrevMonth = () => {
		let day = '01';
		let month = getMonthFromValue(value);
		let year = getYearFromValue(value);

		let date = new Date(year, month, day);

		date.setMonth(date.getMonth() - 1);

		let newDay = date.getDate();
		let newMonth = date.getMonth();
		let newYear = date.getFullYear();

		if (newMonth === 0) {
			newMonth = 12;
			newYear = newYear - 1;
		}

		setValue(newDay + '.' + newMonth + '.' + newYear);
	};

	const getCurrentDate = () => {
		let date = new Date();
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();

		if (day.toString().length === 1) {
			day = '0' + day;
		}
		if (month.toString().length === 1) {
			month = '0' + month;
		}

		return year + '-' + month + '-' + day;
	};

	const getSelectedDate = (val) => {
		let dt = getYearFromValue(val) + '-' + getMonthFromValue(val) + '-' + getDayFromValue(val);
		let date = new Date(dt);
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();

		if (day.toString().length === 1) {
			day = '0' + day;
		}
		if (month.toString().length === 1) {
			month = '0' + month;
		}

		return year + '-' + month + '-' + day;
	};

	const selDate = (val) => {
		if (val !== '') {
			let dt = val.split('-');
			let year = dt[0];
			let month = dt[1];
			let day = dt[2];

			if (day.toString().length === 2) {
				if (day.toString().substr(0, 1) === '0') {
					day = day.toString().substr(1, 1);
				}
			}

			if (month.toString().length === 2) {
				if (month.toString().substr(0, 1) === '0') {
					month = month.toString().substr(1, 1);
				}
			}

			returnValue(day + '.' + month + '.' + year);
		} else {
			returnValue(val);
		}

		close();
	};

	const selMonth = (val) => {
		let day = getDayFromValue(value);
		let month = val + 1;
		let year = getYearFromValue(value);

		if (day.toString().length === 1) {
			day = '0' + day;
		}
		if (month.toString().length === 1) {
			month = '0' + month;
		}

		setValue(day + '.' + month + '.' + year);
		setShowMonth(false);
	};

	useEffect(() => {
		if (showYear) {
			let pYear = year - 1;
			document.getElementById('year_' + pYear).scrollIntoView();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showYear]);

	const handleShowYear = () => {
		setShowYear(true);
	};

	const selYear = (val) => {
		let day = getDayFromValue(value);
		let month = getMonthFromValue(value);
		let year = val;

		if (day.toString().length === 1) {
			day = '0' + day;
		}
		if (month.toString().length === 1) {
			month = '0' + month;
		}

		setValue(day + '.' + month + '.' + year);
		setShowYear(false);
	};

	let now = getCurrentDate();
	let selected = getSelectedDate(defaultValue);

	const month = getMonthFromValue(value);
	const monthName = getMonthNameFromValue(value);
	const year = getYearFromValue(value);

	const dayNamesOfWeek = ['Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'];
	const listDays = [];

	dayNamesOfWeek.forEach((item, index) => {
		listDays.push(
			<td className="calendarin-head" key={index}>
				<b>{item}</b>
			</td>
		);
	});

	const listOfMonths = [];

	monthNames.forEach((item, index) => {
		listOfMonths.push(
			<div className={`cr_p select_item ${parseInt(month) === index + 1 ? 'selected' : ''} `} key={index} onClick={() => setValue('01.' + item.index + '.' + year)} onClickCapture={() => selMonth(index)}>
				{item.name}
			</div>
		);
	});

	const listOfYears = [];
	const fromYear = parseInt(year) - 30;
	const toYear = parseInt(year) + 30;

	for (let i = fromYear; i <= toYear; i++) {
		listOfYears.push(
			<div className={`cr_p select_item ${parseInt(year) === i ? 'selected' : ''} `} id={`year_` + i} key={i} onClick={() => selYear(i)}>
				{i}
			</div>
		);
	}

	const getCalendar = () => {
		let calendar = [];

		let day = '01';
		let month = getMonthFromValue(value);
		let year = getYearFromValue(value);

		let date = new Date(year, month, day);
		date.setMonth(date.getMonth() - 1);

		let firstDay = date.getDay();

		if (firstDay === 0) {
			firstDay = 7;
		}

		let lastDay = new Date(year, month, 0).getDate();

		let dayCounter = 0;
		let dayCounter2 = 0;

		for (let i = 0; i < 6; i++) {
			let row = [];

			for (let j = 0; j < 7; j++) {
				dayCounter++;

				if (dayCounter >= firstDay && dayCounter <= lastDay + firstDay - 1) {
					let curDate = new Date(year, month - 1, dayCounter - firstDay + 1);
					let curDateYear = curDate.getFullYear();
					let curDateMonth = curDate.getMonth() + 1;
					let curDateDay = curDate.getDate();
					if (curDateMonth.toString().length === 1) {
						curDateMonth = '0' + curDateMonth;
					}
					if (curDateDay.toString().length === 1) {
						curDateDay = '0' + curDateDay;
					}
					let curDT = curDateYear + '-' + curDateMonth + '-' + curDateDay;

					let dots = [];

					if (typeof dataEvents[curDT] != 'undefined') {
						let i = -1;

						dataEvents[curDT].forEach((item, index) => {
							++i;
							let leftPos = 0.3 + i * 0.6;
							dots.push(<div className="calendar-dot" key={index} style={{left: leftPos + 'rem', backgroundColor: item}}></div>);
						});
					}

					row.push(
						<td className={`calendar-day ${now === curDT ? 'curday' : ''} ${selected === curDT ? 'selday' : ''} cr_p pos_r`} key={j} onClick={() => selDate(curDT)}>
							{dayCounter - firstDay + 1}
							{dots}
						</td>
					);
				} else {
					if (dayCounter < firstDay) {
						let prevMonth = new Date(year, month - 1, 0).getDate() - (firstDay - dayCounter - 1);
						row.push(
							<td className="calendar-day gray" key={j}>
								{prevMonth}
							</td>
						);
					} else {
						dayCounter2++;
						row.push(
							<td className="calendar-day gray" key={j}>
								{dayCounter2}
							</td>
						);
					}
				}
			}

			calendar.push(<tr key={i}>{row}</tr>);
		}

		return calendar;
	};

	return (
		<>
			{show ? (
				<div
					className="popup"
					onClick={() => {
						close();
					}}
					style={{zIndex: '2000'}}
				>
					<div className="popup_content" style={{width: '35rem'}} onClick={(e) => e.stopPropagation()}>
						<div className="popup_content_in_body">
							<div className="popup_content_in_body_in" style={{padding: '1rem 1rem 0rem 1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
								<div className="" style={{width: '20%'}}>
									<img className="icon_left cr_p mr_2" onClick={() => setPrevMonth()} alt="" />
								</div>
								<div className="" style={{width: '30%'}}>
									<span className="cr_p mr_05" onClick={() => setShowMonth(true)}>
										{monthName}
									</span>
									<span className="cr_p" onClick={() => handleShowYear()}>
										{year}
									</span>
								</div>
								<div className="" style={{width: '20%'}}>
									<img className="icon_right ml_2 cr_p" onClick={() => setNextMonth()} alt="" />
								</div>
							</div>

							<div className="popup_content_in_body_in" style={{padding: '0rem 1rem 0rem 1rem', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
								<div className="zero h1" />
								<table className="calendar" cellPadding={0} cellSpacing={0}>
									<tbody>
										<tr>{listDays}</tr>
										{getCalendar()}
									</tbody>
								</table>
								<div className="zero h1" />
								<button
									className="button"
									onClick={() => {
										close();
									}}
								>
									Zpět
								</button>
								<div className="zero h1" />
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}

			{showMonth ? (
				<div className="popup" style={{zIndex: '2001'}}>
					<div className="popup_content" style={{width: '20rem'}}>
						<div className="popup_content_in_body">
							<div className="popup_content_in_body_in" style={{padding: '1rem 1rem 0rem 1rem', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
								<div className="zero h0" />
								{listOfMonths}
								<div className="zero h05" />
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}

			{showYear ? (
				<div className="popup" style={{zIndex: '2001'}}>
					<div className="popup_content" id="year_sel" style={{width: '20rem'}}>
						<div className="popup_content_in_body">
							<div className="popup_content_in_body_in" style={{padding: '1rem 1rem 0rem 1rem', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
								<div className="zero h0" />
								<div id="cal_years">{listOfYears}</div>
								<div className="zero h05" />
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
