import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import FormText from './FormText.js';

export default function EshopItemNew({showNew, closeNew, updateEshopItemRowNew}) {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [sortorder, setSortorder] = useState('');
	const [sortorderError, setSortorderError] = useState('');
	const [price, setPrice] = useState('');
	const [priceError, setPriceError] = useState('');
	const [img, setImg] = useState('');
	const [imgError, setImgError] = useState('');

	const nameRef = useRef(null);
	const sortorderRef = useRef(null);
	const priceRef = useRef(null);
	const imgRef = useRef(null);

	useEffect(() => {
		setName('');
		setSortorder('');
		setPrice('');
		setImg('');
	}, [showNew]);

	const handleSubmit = (e) => {
		e.preventDefault();

		setNameError('');
		setSortorderError('');
		setPriceError('');
		setImgError('');

		let localError = 0;

		if (!name) {
			localError = 1;
			setNameError('povinné');
			nameRef.current.focus();
		}

		if (!sortorder) {
			if (localError === 0) {
				sortorderRef.current.focus();
			}
			localError = 1;
			setSortorderError('povinné');
		}

		if (!price) {
			if (localError === 0) {
				priceRef.current.focus();
			}
			localError = 1;
			setPriceError('povinné');
		}

		if (localError === 0) {
			setLoading(true);

			post('saveItemNew', {NAME: name, SORTORDER: sortorder, PRICE: price, IMG: img})
				.then((res) => {
					closeNew();

					if (res.data > 0) {
						updateEshopItemRowNew({ID: res.data, NAME: name, SORTORDER: sortorder, PRICE: price, IMG: img, SHOWINWALLET: 1});
					}

					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	return (
		<>
			{showNew ? (
				<div className="popup">
					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">Nové zboží</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeNew();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in">
									<FormText type="text" label="Název" value={name} setValue={setName} ref={nameRef} error={nameError} />

									<div className="fl_l" style={{width: '48%'}}>
										<FormText type="text" label="Pořadí" value={sortorder} setValue={setSortorder} ref={sortorderRef} error={sortorderError} />
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<FormText type="text" label="Cena" value={price} setValue={setPrice} ref={priceRef} error={priceError} />
									</div>

									<FormText type="text" label="Obrázek" value={img} setValue={setImg} ref={imgRef} error={imgError} />

									<div className="zero h0" />
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={loading} />
									<button
										className="button"
										onClick={() => {
											closeNew();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
