import React, {useState, useRef, useEffect} from 'react';
import AppHeader from '../components/AppHeader.js';
import post from '../utils/post.js';
import FormText from '../components/FormText.js';
import validateRestorePassword from '../utils/validateRestorePassword.js';

export default function User({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [firstNameError, setFirstNameError] = useState('');
	const [lastName, setLastName] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [nickname, setNickname] = useState('');
	const [nicknameError, setNicknameError] = useState('');
	const [age, setAge] = useState('');
	const [ageError, setAgeError] = useState('');
	const [myweight, setMyweight] = useState('');
	const [myweightError, setMyweightError] = useState('');
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [password2, setPassword2] = useState('');
	const [passwordError2, setPasswordError2] = useState('');
	const [result, setResult] = useState('');

	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const nicknameRef = useRef();
	const ageRef = useRef();
	const myweightRef = useRef();
	const passwordRef = useRef();
	const passwordRef2 = useRef();

	useEffect(() => {
		getData('');
	}, []);

	const getData = () => {
		setLoading(true);

		post('getSetting', {})
			.then((res) => {
				setFirstName(res.data.FIRSTNAME);
				setLastName(res.data.LASTNAME);
				setNickname(res.data.NICKNAME);
				setAge(res.data.AGE);
				setMyweight(res.data.WEIGHT);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setFirstNameError('');
		setLastNameError('');
		setNicknameError('');
		setAgeError('');
		setMyweightError('');
		setPasswordError('');
		setPasswordError2('');
		setResult('');

		let localError = 0;

		if (!firstName) {
			if (localError === 0) {
				firstNameRef.current.focus();
			}
			localError = 1;
			setFirstNameError('povinné');
		}

		if (!lastName) {
			if (localError === 0) {
				lastNameRef.current.focus();
			}
			localError = 1;
			setLastNameError('povinné');
		}

		if (password !== '') {
			const sError = validateRestorePassword(password, password2);
			setPasswordError(sError.password);
			setPasswordError2(sError.password2);

			if (sError.password !== '') {
				if (localError === 0) {
					passwordRef.current.focus();
				}
				localError = 1;
			}

			if (sError.password2 !== '') {
				if (localError === 0) {
					passwordRef2.current.focus();
				}
				localError = 1;
			}
		}

		if (localError === 0) {
			setLoading(true);

			post('saveSetting', {firstName, lastName, nickname, age, myweight, password, password2})
				.then((res) => {
					setLoading(false);
					setPassword('');
					setPassword2('');
					setResult('Nastavení bylo uloženo.');
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					<div className="list">
						<div className="list_in">
							<form method="POST" onSubmit={handleSubmit}>
								<div className="zero h1" />
								<div className="fl_l" style={{width: '48%'}}>
									<FormText type="text" label="Jméno" value={firstName} setValue={setFirstName} ref={firstNameRef} error={firstNameError} />
								</div>
								<div className="fl_r" style={{width: '48%'}}>
									<FormText type="text" label="Příjmení" value={lastName} setValue={setLastName} ref={lastNameRef} error={lastNameError} />
								</div>

								<FormText type="text" label="Přezdívka" value={nickname} setValue={setNickname} ref={nicknameRef} error={nicknameError} />

								<div className="fl_l" style={{width: '48%'}}>
									<FormText type="number" label="Věk" value={age} setValue={setAge} ref={ageRef} error={ageError} />
								</div>
								<div className="fl_r" style={{width: '48%'}}>
									<FormText type="number" label="Hmotnost" value={myweight} setValue={setMyweight} ref={myweightRef} error={myweightError} />
								</div>

								<div className="fl_l" style={{width: '48%'}}>
									<FormText type="password" label="Heslo" value={password} setValue={setPassword} ref={passwordRef} error={passwordError} />
								</div>
								<div className="fl_r" style={{width: '48%'}}>
									<FormText type="password" label="Znovu hesla" value={password2} setValue={setPassword2} ref={passwordRef2} error={passwordError2} />
								</div>

								<div className="zero h1" />
								<input type="submit" name="button_save" value="Uložit" className="button green mr_1" disabled={loading} />
								<div className="zero h1" />
								<div>{result}</div>
								<div className="zero h1" />
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
