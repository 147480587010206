import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import ConfirmPopup from '../components/ConfirmPopup.js';

export default function Eshop({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [data_history, setDataHistory] = useState([]);
	const [data_history_totals, setDataHistoryTotals] = useState(0);
	const [topay_totals, setTopayTotals] = useState(0);
	const [data_tmp, setDataTmp] = useState([]);
	const [data_tmp_totals, setDataTmpTotals] = useState(0);
	const [showInfoPopup, setShowInfoPopup] = useState(false);
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);
	const [data_item, setDataItem] = useState([]);
	const [offset, setOffset] = useState(0);
	const [loadedCnt, setLoadedCnt] = useState(0);

	useEffect(() => {
		getData('', offset);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadNext = () => {
		setOffset((prevOffset) => {
			let newOffset = prevOffset + usrInfo.LIST_ROWS;
			getData('', prevOffset + usrInfo.LIST_ROWS);
			return newOffset;
		});
	};

	const getData = (search, offset) => {
		setLoading(true);

		post('getListEshop', {search: search, offset: offset})
			.then((res) => {
				if (offset === 0) {
					setData(res.data.data);
				} else {
					setData((prevRows) => {
						const updatedRows = [...data, ...res.data.data];
						return updatedRows;
					});
				}

				setLoadedCnt(res.data.data.length);

				setDataItem(res.data.data_item);
				setDataHistory(res.data.data_history);
				setDataHistoryTotals(res.data.data_history_totals);
				setTopayTotals(res.data.topay_totals);
				setDataTmp(res.data.data_tmp);
				setDataTmpTotals(res.data.data_tmp_totals);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const deleteItemTmp = (item) => {
		setLoading(true);

		post('deleteEshopItemTmp', {ID: item.ID})
			.then((res) => {
				setDataTmp(res.data.data_tmp);
				setDataTmpTotals(res.data.data_tmp_totals);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const showInfo = () => {
		setShowInfoPopup(true);
	};

	const closeInfoPopup = () => {
		setShowInfoPopup(false);
	};

	const showConfirm = () => {
		setShowConfirmPopup(true);
	};

	const closeConfirmPopup = () => {
		setShowConfirmPopup(false);
	};

	const confirmConfirmPopup = () => {
		setLoading(true);

		post('confirmEshop', {})
			.then((res) => {
				setDataItem(res.data.data_item);
				setData(res.data.data);
				setDataHistory(res.data.data_history);
				setDataHistoryTotals(res.data.data_history_totals);
				setTopayTotals(res.data.topay_totals);
				setDataTmp(res.data.data_tmp);
				setDataTmpTotals(res.data.data_tmp_totals);
				setLoading(false);
				setShowConfirmPopup(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const addItem = (item) => {
		setLoading(true);

		post('addEshopItem', {ID: item.ID})
			.then((res) => {
				setDataTmp(res.data.data_tmp);
				setDataTmpTotals(res.data.data_tmp_totals);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const list_items = data_item?.map((item, index) => {
		return (
			<div className="eshop_item cr_p" key={'item_' + index} onClick={() => addItem(item)}>
				<div className="eshop_item_img">
					<img className="eshop_item_img_img" src={item.IMG} alt="" />
				</div>
				<div className="zero h05" />
				<div className="eshop_item_text fnt_11">{item.NAME}</div>
				<div className="zero h02" />
				<div className="eshop_item_price fnt_12 fnt_orange">{item.PRICES},-</div>
				<div className="zero h0" />
			</div>
		);
	});

	let cnt_tmp = 0;

	const list_tmp = data_tmp?.map((item, index) => {
		++cnt_tmp;

		return (
			<div className="list" key={'item_' + index}>
				<div className="list_in fnt_15">
					<div className="zero h05" />
					<div className="fl_l mr_1 pos_r" style={{width: '3rem'}}>
						<img className="icon_eshop_item" src={item.IMG} alt="" />
					</div>

					<div className="fl_l txt_l" style={{paddingLeft: '4rem'}}>
						{cnt_tmp}. {item.NAME}
					</div>

					<div className="fl_r pos_r mr_1" style={{width: '3rem'}}>
						<img className="icon1 del small cr_p" onClick={() => deleteItemTmp(item)} alt="" />
					</div>

					<div className="fl_r txt_r" style={{paddingRight: '3.5rem'}}>
						{item.PRICES},-
					</div>
					<div className="zero h05" />
				</div>
			</div>
		);
	});

	let list_history = [];
	let oldItem = [];
	let cnt = 0;
	let pList = [];

	data_history?.forEach((item, index) => {
		++cnt;
		if (cnt === 1) {
			oldItem.ITEMID = item.ITEMID;
		}

		if (oldItem.ITEMID !== item.ITEMID) {
			list_history.push(
				<div className="list" key={'item_' + index}>
					<div className="list_in">
						<div className="fl_l txt_l">{oldItem.NAME}</div>

						{pList}
						<div className="zero h0" />
					</div>
				</div>
			);

			pList = [];
		}

		pList.push(
			<div key={'item_sub_' + index}>
				<div className="fl_r txt_r" style={{width: '8rem'}}>
					{item.PRICE_TOTALS},-
				</div>
				<div className="fl_r txt_r">
					{item.CNT} x {item.PRICES},-
				</div>
				<div className="zero h0" />
			</div>
		);

		if (cnt === data_history.length) {
			list_history.push(
				<div className="list" key={'item_' + data_history.length}>
					<div className="list_in">
						<div className="fl_l txt_l">{item.NAME}</div>

						{pList}
						<div className="zero h0" />
					</div>
				</div>
			);
		}

		oldItem = item;
	});

	const list = data?.map((item, index) => {
		return (
			<div className="list" key={'item_' + index}>
				<div className="list_in">
					<div className="fl_l txt_l">
						{item.CRDTS} - {item.NAME}
					</div>

					<div className="fl_r txt_r">{item.PRICE},-</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search>
					<span className="fnt_16">
						Zbývá zaplatit:{' '}
						<span className="fnt_orange fnt_2">
							<b>{topay_totals},-</b>
						</span>
					</span>
				</Search>
				<ConfirmPopup
					showConfirmPopup={showInfoPopup}
					closeConfirmPopup={closeInfoPopup}
					infoHeader="Jak to funguje"
					infoMain="Především děkujeme za vaši poctivost že přidáte a zaplatíte vše co zkonzumujete. Také se k zařízením chovejte ohleduplně a provádějte nutnou údržbu pokud to vyžaduje (vysypání lógru, vylití odkapané vody a doplnění vody).<br /><br />Použíté nádobí prosím po sobě umyjte a dejte na púvodní místo.<br /><br />Kliknutím na příslušnou položku ji přídáte do nákupního košíku. Pokud omylem přidáte nechtěnou položku tak je možné ji z košíku odstranit křížkem. Nákup potvrdíte tlačítkem 'Potvrdit nákup'.<br /><br />Částku prosím zaplaťte převodem na účet (ikonka platební karty v horní liště). Po připsání částky na účet bude váš nákup srovnán.<br /><br />Nezaplacenou částku uvidíte jako zprávu na úvodní stránce rezervací. Veškeré vaše nákupy lze vidět v historii nákupů."
				/>
				<ConfirmPopup showConfirmPopup={showConfirmPopup} showButtonConfirm cofirmConfirmPopup={confirmConfirmPopup} closeConfirmPopup={closeConfirmPopup} infoHeader="Potvrzení nákupu" infoMain="Opravdu provést nákup?<br /><br />Po potvrzení nákupu je možné částku zaplatit ihned převodem na účet pomocí QR kódu (ikonka platební karty) nebo později po více nákupech.<br /><br />Dlužná částka bude srovnána po přijetí platby." />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					{list_items}
					<div className="zero h1" />

					<span className="fl_l">Nákupní košík</span>
					<div className="zero h1" />

					{list_tmp}
					<div className="list" key={'data_bin_totals'}>
						<div className="list_in fnt_16 fnt_orange">
							<div className="zero h05" />
							<b>
								<div className="fl_l txt_l">CELKEM:</div>
								<div className="fl_r txt_r">{data_tmp_totals},-</div>
							</b>
							<div className="zero h0" />
						</div>
						<div className="zero h05" />
					</div>
					<div className="zero h1" />

					<button className="button fl_l mr_1" onClick={() => showInfo()}>
						Jak to funguje
					</button>

					{list_tmp.length > 0 ? (
						<button className="button green fl_r" onClick={() => showConfirm()}>
							Potvrdit nákup
						</button>
					) : (
						<button className="button green fl_r" disabled>
							Potvrdit nákup
						</button>
					)}

					<div className="zero h4" />

					<span className="fl_l">Historie nákupů</span>
					<div className="zero h1" />

					{list_history}
					<div className="list" key={'data_history_totals'}>
						<div className="list_in">
							<b>
								<div className="fl_l txt_l">CELKEM:</div>
								<div className="fl_r txt_r">{data_history_totals},-</div>
							</b>
							<div className="zero h0" />
						</div>
					</div>
					<div className="zero h2" />

					{list}

					{parseInt(loadedCnt) === parseInt(usrInfo.LIST_ROWS) ? (
						<button className="button small loadNext" onClick={() => loadNext()}>
							Načíst další
						</button>
					) : (
						<span className="fnt_12 loadNext">Byly načteny všechny záznamy</span>
					)}
				</div>
			</div>
		</>
	);
}
