import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import RezervationRecsEdit from '../components/RezervationRecsEdit.js';

export default function RezervationRecs({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0);
	const [loadedCnt, setLoadedCnt] = useState(0);
	const [showEdit, setShowEdit] = useState(false);

	useEffect(() => {
		let search = '';

		if (typeof window.location.pathname.split('/')[2] != 'undefined') {
			search = decodeURI(window.location.pathname.split('/')[2]);
			setSearchValue(search);
		}

		getData(search, offset);

		if (search !== '') {
			window.history.pushState({}, document.title, '/rezervationrecs');
		}
	}, []);

	const loadNext = () => {
		setOffset((prevOffset) => {
			let newOffset = prevOffset + usrInfo.LIST_ROWS;
			getData(searchValue, prevOffset + usrInfo.LIST_ROWS);
			return newOffset;
		});
	};

	const onChangeSearch = (value) => {
		setSearchValue(value);
		setOffset(0);
		getData(value, 0);
	};

	const getData = (search, offset) => {
		setLoading(true);

		post('getListRezervationRecs', {search: search, offset: offset})
			.then((res) => {
				if (offset === 0) {
					setData(res.data);
				} else {
					setData((prevRows) => {
						const updatedRows = [...data, ...res.data];
						return updatedRows;
					});
				}

				setLoadedCnt(res.data.length);

				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleEdit = (rec) => {
		setShowEdit(true);
		setDataDetail(rec);
	};

	const closeEdit = () => {
		setShowEdit(false);
		setDataDetail({});
	};

	const updateRezervationRecsRowEdit = (row) => {
		const newData = data.map((item) => {
			if (item.ID === row.ID) {
				return row;
			}

			return item;
		});

		setData(newData);
	};

	const updateRezervationRecsRowDelete = (row) => {
		const newData = data.filter((item) => {
			return item.ID !== row;
		});

		setData(newData);
	};

	const list = data.map((item, index) => {
		return (
			<div className="list" key={'item_' + index}>
				<div className={`list_in ` + (item.TODAY == 1 ? 'fnt_yellow' : item.FUTURE == 1 ? 'fnt_green' : '')}>
					<div className="fl_l">
						<img className={`icon1 edit fl_l cr_p mr_1`} onClick={() => handleEdit(item)} alt="" />
					</div>
					<div className="fl_l txt_l" style={{width: '100px'}}>
						{item.RESERVATIONDTS}
					</div>
					<div className="fl_l txt_l" style={{width: '35px'}}>
						{item.RESERVATIONLNG_H}h
					</div>
					<div className="fl_l txt_l ml_1">
						<b>
							{item.LASTNAME} {item.FIRSTNAME}
						</b>{' '}
						{item.NICKNAME !== '' ? `(${item.NICKNAME})` : ''}
					</div>
					<div className={`fl_r txt_r ${item.ADD_PERSON_PAYED == 1 ? 'fnt_green' : 'fnt_red'}`}>
						<b>{item.ADD_PERSON > 0 ? '+' + item.ADD_PERSON : <>&nbsp;</>}</b>
					</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showSearch searchValue={searchValue} onChangeSearch={(e) => onChangeSearch(e)}></Search>
				<RezervationRecsEdit showEdit={showEdit} closeEdit={closeEdit} updateRezervationRecsRowEdit={updateRezervationRecsRowEdit} updateRezervationRecsRowDelete={updateRezervationRecsRowDelete} data={dataDetail} />

				<div className={`page ${usrInfo.usrId === 1 ? ' more' : ''}`}>
					{loading ? <div className="loading" /> : ''}

					{list}

					{parseInt(loadedCnt) === parseInt(usrInfo.LIST_ROWS) ? (
						<button className="button small loadNext" onClick={() => loadNext()}>
							Načíst další
						</button>
					) : (
						<span className="fnt_12 loadNext">Byly načteny všechny záznamy</span>
					)}
				</div>
			</div>
		</>
	);
}
