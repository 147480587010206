import React, {useState} from 'react';
import Calendar from './Calendar.js';

const FormDate = React.forwardRef(({readOnly = false, label, value, setValue, error, placeholder = '', maxLength, iconPosTop = '2.4rem'}, ref) => {
	const [showCal, setShowCal] = useState(false);

	if (placeholder === '') {
		placeholder = label;
	}

	if (!maxLength) {
		maxLength = 100;
	}

	const showCalendar = () => {
		setShowCal(true);
	};

	const closeCal = () => {
		setShowCal(false);
	};

	const returnValue = (val) => {
		setValue(val);
	};

	const iconClick = () => {
		if (readOnly) {
			showCalendar();
		}
	};

	return (
		<>
			<div className="input-div pos_r">
				<div className="input-label">{label}</div>
				<div className="input-error">{error}</div>
				<input className={`input-text ${readOnly ? 'cr_p' : ''} `} type="text" maxLength={maxLength} readOnly={readOnly} placeholder={placeholder} ref={ref} value={value} onChange={(e) => setValue(e.target.value)} onClick={() => iconClick()} />
				<span className="input-text-date-cal-icon" style={{top: iconPosTop}} onClick={() => showCalendar()}></span>
			</div>

			<Calendar show={showCal} close={closeCal} defaultValue={value} returnValue={returnValue} />
		</>
	);
});

export default FormDate;
