import React from 'react';

export default function ConfirmPopup({showConfirmPopup = false, showButtonConfirm = false, cofirmConfirmPopup = () => {}, showButtonDelete = false, deleteConfirmPopup = () => {}, closeConfirmPopup = () => {}, infoHeader = '&nbsp;', infoMain = '&nbsp;', children}) {
	return (
		<>
			{showConfirmPopup ? (
				<div className="popup" style={{zIndex: '2001'}}>
					<div className="popup_content">
						<div className="popup_content_in_head">
							<div className="popup_content_in_head_in">{infoHeader}</div>
							<img
								alt=""
								className="popup_content_in_head_in-close"
								onClick={() => {
									closeConfirmPopup();
								}}
							/>
						</div>
						<div className="popup_content_in_body">
							<div className="popup_content_in_body_in">
								<div className="zero h1" />
								<span dangerouslySetInnerHTML={{__html: infoMain}}></span>
								{children}
								<div className="zero h1" />
							</div>
						</div>
						<div className="popup_content_in_foot">
							<div className="popup_content_in_foot_in">
								{showButtonConfirm ? (
									<button
										className="button green mr_2"
										onClick={() => {
											cofirmConfirmPopup();
										}}
									>
										Potvrdit
									</button>
								) : (
									''
								)}

								{showButtonDelete ? (
									<button
										className="button red mr_2"
										onClick={() => {
											deleteConfirmPopup();
										}}
									>
										Smazat
									</button>
								) : (
									''
								)}

								<button
									className="button"
									onClick={() => {
										closeConfirmPopup();
									}}
								>
									Zpět
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
