import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import EshopItemNew from '../components/EshopItemNew.js';
import EshopItemEdit from '../components/EshopItemEdit.js';

export default function EshopItem({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [showNew, setShowNew] = useState(false);
	const [showEdit, setShowEdit] = useState(false);

	useEffect(() => {
		getData('');
	}, []);

	const getData = (search) => {
		setLoading(true);

		post('getListItem', {search: search})
			.then((res) => {
				setData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const saveInWallet = (index, id, val) => {
		setLoading(true);

		post('saveInWallet', {ID: id, SHOWINWALLET: val})
			.then((res) => {
				data[index].SHOWINWALLET = res.data;
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleNew = () => {
		setShowNew(true);
	};

	const closeNew = () => {
		setShowNew(false);
	};

	const updateEshopItemRowNew = (row) => {
		setData([row, ...data]);
	};

	const handleEdit = (rec) => {
		setShowEdit(true);
		setDataDetail(rec);
	};

	const closeEdit = () => {
		setShowEdit(false);
	};

	const updateEshopItemRowEdit = (row) => {
		const newData = data.map((item) => {
			if (item.ID === row.ID) {
				return row;
			}

			return item;
		});

		setData(newData);
	};

	const updateEshopItemRowDelete = (row) => {
		const newData = data.filter((item) => {
			return item.ID !== row;
		});

		setData(newData);
	};

	const list = data.map((item, index) => {
		return (
			<div className="list" key={'item_' + index}>
				<div className="list_in">
					<div className="fl_l">
						<img className={`icon1 eshop ${parseInt(item.SHOWINWALLET) === 1 ? 'active' : ''} fl_l cr_p mr_2`} onClick={() => saveInWallet(index, item.ID, item.SHOWINWALLET)} alt="" />
						<img className={`icon1 edit fl_l cr_p mr_2`} onClick={() => handleEdit(item)} alt="" />
					</div>
					<div className="fl_l">
						{item.SORTORDER}. {item.NAME}
					</div>
					<div className="fl_r">{item.PRICE},-</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showNew handleNew={handleNew} />
				<EshopItemNew showNew={showNew} closeNew={closeNew} updateEshopItemRowNew={updateEshopItemRowNew} />
				<EshopItemEdit showEdit={showEdit} closeEdit={closeEdit} updateEshopItemRowEdit={updateEshopItemRowEdit} updateEshopItemRowDelete={updateEshopItemRowDelete} data={dataDetail} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>{list}</div>
			</div>
		</>
	);
}
