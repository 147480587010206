import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';

export default function Message({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0);
	const [loadedCnt, setLoadedCnt] = useState(0);

	useEffect(() => {
		getData('', offset);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadNext = () => {
		setOffset((prevOffset) => {
			let newOffset = prevOffset + usrInfo.LIST_ROWS;
			getData(searchValue, prevOffset + usrInfo.LIST_ROWS);
			return newOffset;
		});
	};

	const onChangeSearch = (value) => {
		setSearchValue(value);
		setOffset(0);
		getData(value, 0);
	};

	const getData = (search, offset) => {
		setLoading(true);

		post('getListMessagesUsr', {search: search, offset: offset})
			.then((res) => {
				if (offset === 0) {
					setData(res.data.data);
				} else {
					setData((prevRows) => {
						const updatedRows = [...data, ...res.data.data];
						return updatedRows;
					});
				}

				setLoadedCnt(res.data.data.length);

				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleReaded = (ID) => {
		post('saveMessagesUsrReaded', {ID: ID})
			.then((res) => {
				setData((prevRows) => {
					const updatedRows = data.map((item, idex) => {
						if (item.ID === ID) {
							item.READED = 1;
						}

						return item;
					});

					return updatedRows;
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	let list = [];

	data?.forEach((item, index) => {
		list.push(
			<div className="list" key={index}>
				<div className="list_in">
					<div className="fl_l txt_l" style={{width: '100%'}}>
						<span className="fl_l">
							<b>{item.CRDTS}</b>
						</span>
						<span className="fl_r">Přečteno: {item.READEDDT}</span>
						<div className="zero h1" />
						<span dangerouslySetInnerHTML={{__html: item.NOTE}}></span>
						<div className="zero h0" />
					</div>
					<div className="zero h1" />

					{item.READED === 0 ? (
						<button
							className="button green small"
							onClick={() => {
								handleReaded(item.ID);
							}}
						>
							Rozumím
						</button>
					) : (
						''
					)}
					<div className="zero h1" />
				</div>
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showSearch searchValue={searchValue} onChangeSearch={(e) => onChangeSearch(e)}></Search>

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					{list}

					{parseInt(loadedCnt) === parseInt(usrInfo.LIST_ROWS) ? (
						<button className="button small loadNext" onClick={() => loadNext()}>
							Načíst další
						</button>
					) : (
						<span className="fnt_12 loadNext">Byly načteny všechny záznamy</span>
					)}
				</div>
			</div>
		</>
	);
}
