import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import FormText from './FormText.js';
import FormDate from './FormDate.js';
import FormSelect from './FormSelect.js';
import FormTextArea from './FormTextArea.js';
import ConfirmPopup from './ConfirmPopup.js';

export default function PaymentEdit({showEdit, closeEdit, updateRow, data, copyToNewPay, searchValue = ''}) {
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState(0);
	const [amountError, setAmountError] = useState('');
	const [note, setNote] = useState('');
	const [typeId, setTypeId] = useState(1);
	const [paymentDT, setPaymendDT] = useState('');
	const [usrId, setUsrId] = useState(0);
	const [userName, setUserName] = useState('[None]');
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);

	const amountRef = useRef();
	const noteRef = useRef();
	const paymendDTRef = useRef();
	const userNameRef = useRef();

	useEffect(() => {
		if (showEdit) {
			setAmount(data.item.AMOUNT || '');
			setNote(data.item.NOTE || '');
			setTypeId(data.item.TYPEID || 1);
			setPaymendDT(data.item.PAYMENTDTS || '');
			setUsrId(data.item.USRID || 0);
			setUserName(data.item.USRNAME || '[None]');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showEdit]);

	const handleSubmit = (e) => {
		e.preventDefault();

		setAmountError('');

		let localError = 0;

		if (!amount || amount === 0) {
			localError = 1;
			setAmountError('povinné');
			amountRef.current.focus();
		}

		if (localError === 0) {
			setLoading(true);

			post('savePaymentOld', {AMOUNT: amount, NOTE: note, TYPEID: typeId, PAYMENTDT: paymentDT, USRID: usrId, ID: data.item.ID, search: searchValue})
				.then((res) => {
					closeEdit();
					updateRow(res.data);
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const setType = (id) => {
		setTypeId(id);

		if (parseInt(id) === 2) {
			setUsrId(0);
			setUserName('[None]');
		}
	};

	const setMember = (val) => {
		setUsrId(val.ID);
		setUserName(val.NAME);
	};

	const copyToNew = (e) => {
		e.preventDefault();
		closeEdit();
		copyToNewPay();
	};

	const handleConfirmPopup = (e) => {
		e.preventDefault();
		setShowConfirmPopup(true);
	};

	const closeConfirmPopup = () => {
		setShowConfirmPopup(false);
	};

	const deleteConfirmPopup = () => {
		setLoading(true);

		post('deletePayment', {ID: data.item.ID})
			.then((res) => {
				closeConfirmPopup();
				closeEdit();
				updateRow(res.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	return (
		<>
			<ConfirmPopup showConfirmPopup={showConfirmPopup} showButtonDelete deleteConfirmPopup={deleteConfirmPopup} closeConfirmPopup={closeConfirmPopup} infoHeader="Smazání záznamu" infoMain="Opravdu chceš smazat tento záznam?" />

			{showEdit ? (
				<div className="popup">
					{loading ? <div className="loading" /> : ''}

					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">Úprava platby</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeEdit();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in">
									<div className="fl_l" style={{width: '48%'}}>
										<div className="zero h2" />
										<div className="fl_l mr_2 cr_p" onClick={() => setType(1)}>
											<button className={`button check ${parseInt(typeId) === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
											<span className="fl_l mt_02">Příjmy</span>
										</div>
										<div className="fl_l cr_p" onClick={() => setType(2)}>
											<button className={`button check ${parseInt(typeId) === 2 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
											<span className="fl_l mt_02">Výdaje</span>
										</div>
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<FormDate label="Datum" maxLength="10" readOnly value={paymentDT} setValue={setPaymendDT} ref={paymendDTRef} error="" />
									</div>

									<div className="zero h0" />

									<div className="fl_l" style={{width: '48%'}}>
										{parseInt(typeId) === 1 ? <FormSelect type="text" label="Člen" value={usrId} valueLabel={userName} setValue={setMember} options={data.user} ref={userNameRef} error="" /> : ''}
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<FormText type="text" label="Částka" value={amount} setValue={setAmount} ref={amountRef} error={amountError} />
									</div>

									<div className="zero h0" />
									<FormTextArea type="text" label="Poznámka" height="12rem" value={note} setValue={setNote} ref={noteRef} error="" />

									<div className="zero h4" />

									{parseInt(typeId) === 1 ? <input type="submit" name="button_save" value="Kopírovat do nové" className="button green mr_2" onClick={(e) => copyToNew(e)} /> : ''}

									<div className="zero h4" />
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={loading} />
									<button
										className="button red mr_2"
										onClick={(e) => {
											handleConfirmPopup(e);
										}}
									>
										Smazat
									</button>
									<button
										className="button"
										onClick={() => {
											closeEdit();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
