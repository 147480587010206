import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';

export default function MessageSysadminReaded({showReaded, closeReaded, messageId}) {
	const [data, setData] = useState([]);
	const [search, setSearch] = useState('');
	const [searchOptions, setSearchOptions] = useState([]);

	useEffect(() => {
		if (showReaded) {
			setSearch('');
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showReaded]);

	const getData = () => {
		post('getReadedMessage', {ID: messageId})
			.then((res) => {
				setData(res.data);
				setSearchOptions(res.data);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const setVals = (e) => {
		setSearch(e.target.value);

		let pom = data.filter((item) => {
			return item.USRNAME.toLowerCase().includes(e.target.value.toLowerCase());
		});

		setSearchOptions(pom);
	};

	const list = searchOptions.map((line, index) => {
		return (
			<div className="list" key={index}>
				<div className="list_in">
					<div className="fl_l txt_l">
						{line.CRDTS} - {line.USRNAME}
					</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	return (
		<>
			{showReaded ? (
				<div className="popup">
					<div className="popup_content">
						<div className="popup_content_in_head">
							<div className="popup_content_in_head_in">Zprávu četli</div>
							<img
								alt=""
								className="popup_content_in_head_in-close"
								onClick={() => {
									closeReaded();
								}}
							/>
						</div>
						<div className="popup_content_in_body_in" style={{padding: '1rem 1rem 0rem 1rem', marginBottom: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
							<input className="input-text" type="text" maxLength={100} placeholder="Vyhledat" value={search} onChange={(e) => setVals(e)} />
						</div>
						<div className="zero h0" />

						<div className="popup_content_in_body">
							<div className="popup_content_in_body_in">
								{list}
								<div className="zero h0" />
							</div>
						</div>
						<div className="popup_content_in_foot">
							<div className="popup_content_in_foot_in">
								<button
									className="button"
									onClick={() => {
										closeReaded();
									}}
								>
									Zpět
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
