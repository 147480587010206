import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import ConfirmPopup from '../components/ConfirmPopup.js';

export default function EshopSysadmin({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataGroupped, setDataGroupped] = useState([]);
	const [data_groupped_totals, setDataGrouppedTotals] = useState(0);
	const [data_notpayed, setDataNotpayed] = useState([]);
	const [data_notpayed_totals, setDataNotpayedTotals] = useState(0);
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);
	const [itemId, setItemId] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0);
	const [loadedCnt, setLoadedCnt] = useState(0);

	useEffect(() => {
		let search = '';

		if (typeof window.location.pathname.split('/')[2] != 'undefined') {
			search = decodeURI(window.location.pathname.split('/')[2]);
			setSearchValue(search);
		}

		getData(search, offset);

		if (search !== '') {
			window.history.pushState({}, document.title, '/eshopsysadmin');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeSearch = (value) => {
		setSearchValue(value);
		setOffset(0);
		getData(value, 0);
	};

	const loadNext = () => {
		setOffset((prevOffset) => {
			let newOffset = prevOffset + usrInfo.LIST_ROWS;
			getData(searchValue, prevOffset + usrInfo.LIST_ROWS);
			return newOffset;
		});
	};

	const getData = (search, offset) => {
		setLoading(true);

		post('getListSysadminEshop', {search: search, offset: offset})
			.then((res) => {
				if (offset === 0) {
					setData(res.data.data);
				} else {
					setData((prevRows) => {
						const updatedRows = [...data, ...res.data.data];
						return updatedRows;
					});
				}

				setLoadedCnt(res.data.data.length);

				setDataGroupped(res.data.data_groupped);
				setDataGrouppedTotals(res.data.data_groupped_totals);
				setDataNotpayed(res.data.data_notpayed);
				setDataNotpayedTotals(res.data.data_notpayed_totals);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const updateEshopItemRowDelete = (row) => {
		const newData = data.filter((item) => {
			return item.ID !== row;
		});

		setData(newData);
	};

	const handleConfirmPopup = (ID) => {
		setItemId(ID);
		setShowConfirmPopup(true);
	};

	const closeConfirmPopup = () => {
		setShowConfirmPopup(false);
	};

	const deleteConfirmPopup = () => {
		setLoading(true);

		post('deleteSysadminEhopItem', {ID: itemId})
			.then((res) => {
				closeConfirmPopup();
				updateEshopItemRowDelete(itemId);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	let list_groupped = [];
	let oldItem = [];
	let cnt = 0;
	let pList = [];

	dataGroupped?.forEach((item, index) => {
		++cnt;
		if (cnt === 1) {
			oldItem.ITEMID = item.ITEMID;
		}

		if (oldItem.ITEMID !== item.ITEMID) {
			list_groupped.push(
				<div className="list" key={'item_' + index}>
					<div className="list_in">
						<div className="fl_l txt_l">{oldItem.NAME}</div>

						{pList}
						<div className="zero h0" />
					</div>
				</div>
			);

			pList = [];
		}

		pList.push(
			<div key={'item_sub_' + index}>
				<div className="fl_r txt_r" style={{width: '8rem'}}>
					{item.PRICE_TOTALS},-
				</div>
				<div className="fl_r txt_r">
					{item.CNT} x {item.PRICES},-
				</div>
				<div className="zero h0" />
			</div>
		);

		if (cnt === dataGroupped.length) {
			list_groupped.push(
				<div className="list" key={'item_' + dataGroupped.length}>
					<div className="list_in">
						<div className="fl_l txt_l">{item.NAME}</div>

						{pList}
						<div className="zero h0" />
					</div>
				</div>
			);
		}

		oldItem = item;
	});

	const list_notpayed = data_notpayed?.map((item, index) => {
		return (
			<div className="list" key={'item_' + index}>
				<div className="list_in fnt_orange">
					<div className="fl_l txt_l">{item.USRNAME}</div>

					<div className="fl_r txt_r">
						{item.NAME}
						<div className="zero h0" />
						{item.TOPAY_TOTAL},-
					</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	const list = data?.map((item, index) => {
		return (
			<div className="list" key={'item_' + index}>
				<div className="list_in">
					<div className="fl_l txt_l">
						{item.USRNAME}
						<div className="zero h0" />
						{item.CRDTS}
					</div>

					{usrInfo.usrId === 1 ? (
						<div className="fl_r txt_r ml_1">
							<img className="icon1 del cr_p" onClick={() => handleConfirmPopup(item.ID)} alt="" />
						</div>
					) : (
						<></>
					)}

					<div className="fl_r txt_r">
						{item.NAME}
						<div className="zero h0" />
						{item.PRICE},-
					</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showSearch searchValue={searchValue} onChangeSearch={(e) => onChangeSearch(e)} />
				<ConfirmPopup showConfirmPopup={showConfirmPopup} showButtonDelete deleteConfirmPopup={deleteConfirmPopup} closeConfirmPopup={closeConfirmPopup} infoHeader="Smazání záznamu" infoMain="Opravdu chceš smazat tento záznam?" />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					{list_notpayed}
					<div className="list" key={'data_notpayed_totals'}>
						<div className="list_in fnt_orange">
							<b>
								<div className="fl_l txt_l">NEZAPLACENO:</div>
								<div className="fl_r txt_r">{data_notpayed_totals},-</div>
							</b>
							<div className="zero h0" />
						</div>
					</div>
					<div className="zero h2" />

					{list_groupped}
					<div className="list" key={'data_groupped_totals'}>
						<div className="list_in">
							<b>
								<div className="fl_l txt_l">CELKEM:</div>
								<div className="fl_r txt_r">{data_groupped_totals},-</div>
							</b>
							<div className="zero h0" />
						</div>
					</div>
					<div className="zero h2" />

					{list}

					{parseInt(loadedCnt) === parseInt(usrInfo.LIST_ROWS) ? (
						<button className="button small loadNext" onClick={() => loadNext()}>
							Načíst další
						</button>
					) : (
						<span className="fnt_12 loadNext">Byly načteny všechny záznamy</span>
					)}
				</div>
			</div>
		</>
	);
}
