import React, {useState, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import MessageSysadminReaded from '../components/MessageSysadminReaded.js';
import MessageSysadminNew from '../components/MessageSysadminNew.js';
import MessageSysadminEdit from '../components/MessageSysadminEdit.js';

export default function MessageSysadmin({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0);
	const [loadedCnt, setLoadedCnt] = useState(0);
	const [showReaded, setShowReaded] = useState(false);
	const [messageId, setMessageId] = useState(0);
	const [showNew, setShowNew] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [dataDetail, setDataDetail] = useState({});

	useEffect(() => {
		getData('', offset);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadNext = () => {
		setOffset((prevOffset) => {
			let newOffset = prevOffset + usrInfo.LIST_ROWS;
			getData(searchValue, prevOffset + usrInfo.LIST_ROWS);
			return newOffset;
		});
	};

	const onChangeSearch = (value) => {
		setSearchValue(value);
		setOffset(0);
		getData(value, 0);
	};

	const getData = (search, offset) => {
		setLoading(true);

		post('getListMessages', {search: search, offset: offset})
			.then((res) => {
				if (offset === 0) {
					setData(res.data.data);
				} else {
					setData((prevRows) => {
						const updatedRows = [...data, ...res.data.data];
						return updatedRows;
					});
				}

				setLoadedCnt(res.data.data.length);

				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleNew = () => {
		setShowNew(true);
	};

	const closeNew = () => {
		setShowNew(false);
	};

	const updateMessageRowNew = (row) => {
		console.log(row);
		setData((prevRows) => {
			const updatedRows = [row, ...data];
			return updatedRows;
		});
	};

	const handleEdit = (rec) => {
		setShowEdit(true);
		setDataDetail(rec);
	};

	const closeEdit = () => {
		setShowEdit(false);
	};

	const updateMessageRowEdit = (row) => {
		setData((prevRows) => {
			const updatedRows = data.map((item, idex) => {
				if (item.ID === row.ID) {
					item.NOTE = row.NOTE;
					item.NOTES = row.NOTES;
				}

				return item;
			});

			return updatedRows;
		});
	};

	const updateMessageRowDelete = (id) => {
		setData((prevRows) => {
			const updatedRows = data.filter((item, idex) => {
				return item.ID !== id;
			});

			return updatedRows;
		});
	};

	const handleShowReaded = (id) => {
		setMessageId(id);
		setShowReaded(true);
	};

	const closeReaded = () => {
		setShowReaded(false);
	};

	let list = [];

	data?.forEach((item, index) => {
		list.push(
			<div className="list" key={index}>
				<div className="list_in">
					<div className="fl_l txt_l" style={{width: '100%'}}>
						<span className="fl_l">
							<b>{item.CRDTS}</b>
						</span>
						<img className="icon1 edit fl_r cr_p ml_2" onClick={() => handleEdit(item)} alt="" />
						<span className="fl_r lnk2 cr_p" onClickCapture={() => handleShowReaded(item.ID)}>
							{item.READ_CNT} četlo
						</span>

						<div className="zero h1" />
						<span dangerouslySetInnerHTML={{__html: item.NOTES}}></span>
					</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showSearch searchValue={searchValue} onChangeSearch={(e) => onChangeSearch(e)} showNew handleNew={handleNew}></Search>
				<MessageSysadminReaded showReaded={showReaded} closeReaded={closeReaded} messageId={messageId} />
				<MessageSysadminNew showNew={showNew} closeNew={closeNew} updateMessageRowNew={updateMessageRowNew} />
				<MessageSysadminEdit showEdit={showEdit} closeEdit={closeEdit} updateMessageRowEdit={updateMessageRowEdit} data={dataDetail} updateMessageRowDelete={updateMessageRowDelete} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					{list}

					{parseInt(loadedCnt) === parseInt(usrInfo.LIST_ROWS) ? (
						<button className="button small loadNext" onClick={() => loadNext()}>
							Načíst další
						</button>
					) : (
						<span className="fnt_12 loadNext">Byly načteny všechny záznamy</span>
					)}
				</div>
			</div>
		</>
	);
}
