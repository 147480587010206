import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import FormText from './FormText.js';
import FormTextArea from './FormTextArea.js';
import FormSelect from './FormSelect.js';
import ConfirmPopup from './ConfirmPopup.js';

export default function WorkoutEdit({showEdit, closeEdit, updateWorkoutRowEdit, updateWorkoutRowDelete, data, dataMuscles, dataWorkoutOffic}) {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [note, setNote] = useState('');
	const [typeId, setTypeId] = useState(1);
	const [typeId2, setTypeId2] = useState(0);
	const [withmyweight, setWithmyweight] = useState(0);
	const [musclegroupId, setMusclegroupId] = useState(0);
	const [musclegroupName, setMusclegroupName] = useState('[None]');
	const [workoutOfficId, setWorkoutOfficId] = useState(0);
	const [workoutOfficName, setWorkoutOfficName] = useState('[None]');
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);

	const nameRef = useRef(null);
	const noteRef = useRef(null);
	const musclegroupRef = useRef(null);
	const workoutOfficRef = useRef(null);

	useEffect(() => {
		setName(data.NAME || '');
		setNote(data.NOTE || '');
		setTypeId(data.TYPEID || 1);

		if (parseInt(data.TYPEID) === 1) {
			setTypeId2(0);
			setWithmyweight(data.WITHMYWEIGHT || 0);
		} else {
			setTypeId2(data.TYPEID2 || 0);
			setWithmyweight(data.WITHMYWEIGHT || 0);
		}

		setMusclegroupId(data.MUSCLEID || 0);
		setMusclegroupName(data.MUSCLE_NAME || '[None]');
		setWorkoutOfficId(data.WORKOUTOFFICIALID || 0);
		setWorkoutOfficName(data.OFFICIALWORKOUT_NAME || '[None]');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showEdit]);

	const handleSubmit = (e) => {
		e.preventDefault();

		setNameError('');

		let localError = 0;

		if (!name) {
			localError = 1;
			setNameError('povinné');
			nameRef.current.focus();
		}

		if (localError === 0) {
			setLoading(true);

			post('saveWorkoutOld', {ID: data.ID, NAME: name, NOTE: note, TYPEID: typeId, TYPEID2: typeId2, WITHMYWEIGHT: withmyweight, MUSCLEID: musclegroupId, WORKOUTOFFICID: workoutOfficId})
				.then((res) => {
					closeEdit();
					updateWorkoutRowEdit(res.data);
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const setTypeIdVal = (e, val) => {
		e.preventDefault();
		setTypeId(val);

		if (parseInt(val) === 1) {
			setTypeId2(0);
			setTypeId2(0);
		}

		if (parseInt(val) === 2 && parseInt(typeId) !== 2) {
			setWithmyweight(0);
			setTypeId2(1);
		}
	};

	const setTypeId2Val = (e, val) => {
		e.preventDefault();

		if (parseInt(typeId) === 2) {
			setTypeId2(val);
		}
	};

	const setWithmyweightVal = (e, val) => {
		e.preventDefault();

		if (parseInt(typeId) === 1) {
			setWithmyweight((oldValue) => {
				if (val === oldValue) {
					return 0;
				} else {
					return val;
				}
			});
		}
	};

	const setMusclegroup = (val) => {
		setMusclegroupId(val.ID);
		setMusclegroupName(val.NAME);
	};

	const setWorkoutOffic = (val) => {
		setWorkoutOfficId(val.ID);
		setWorkoutOfficName(val.NAME);
	};

	const handleConfirmPopup = (e) => {
		e.preventDefault();
		setShowConfirmPopup(true);
	};

	const closeConfirmPopup = () => {
		setShowConfirmPopup(false);
	};

	const deleteConfirmPopup = () => {
		setLoading(true);

		post('deleteWorkout', {ID: data.ID})
			.then((res) => {
				closeConfirmPopup();
				closeEdit();
				updateWorkoutRowDelete(res.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	return (
		<>
			<ConfirmPopup showConfirmPopup={showConfirmPopup} showButtonDelete deleteConfirmPopup={deleteConfirmPopup} closeConfirmPopup={closeConfirmPopup} infoHeader="Smazání záznamu" infoMain="Opravdu chceš smazat tento záznam?" />

			{showEdit ? (
				<div className="popup">
					<div className="popup_content">
						<form method="POST" onSubmit={handleSubmit}>
							<div className="popup_content_in_head">
								<div className="popup_content_in_head_in">Nový cvik</div>
								<img
									alt=""
									className="popup_content_in_head_in-close"
									onClick={() => {
										closeEdit();
									}}
								/>
							</div>
							<div className="popup_content_in_body">
								<div className="popup_content_in_body_in">
									<FormText type="text" label="Název" value={name} setValue={setName} ref={nameRef} error={nameError} />
									<div className="zero h0" />

									<FormTextArea type="text" label="Poznámka" height="10rem" value={note} setValue={setNote} ref={noteRef} error="" />
									<div className="zero h0" />

									<FormSelect type="text" label="Svalová partie" value={musclegroupId} valueLabel={musclegroupName} setValue={setMusclegroup} options={dataMuscles} ref={musclegroupRef} error="" />
									<div className="zero h0" />

									<FormSelect type="text" label="Oficiální cvik" value={workoutOfficId} valueLabel={workoutOfficName} setValue={setWorkoutOffic} options={dataWorkoutOffic} ref={workoutOfficRef} error="" />
									<div className="zero h1" />

									<div className="fl_l" style={{width: '48%'}}>
										<div key="TYPEID1">
											<div className="fl_l mr_2 cr_p" onClick={(e) => setTypeIdVal(e, 1)}>
												<button className={`button check ${parseInt(typeId) === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
												<span className="fl_l mt_02">Opakování, váha</span>
											</div>
										</div>
										<div className="zero h1" />
										<div key="TYPEID2">
											<div className="fl_l mr_2 cr_p" onClick={(e) => setTypeIdVal(e, 2)}>
												<button className={`button check ${parseInt(typeId) === 2 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
												<span className="fl_l mt_02">Čas</span>
											</div>
										</div>
									</div>
									<div className="fl_r" style={{width: '48%'}}>
										<div key="TYPEID1">
											<div className={`fl_l mr_2 cr_p ${parseInt(typeId) === 2 ? 'button_disabled' : ''}`} onClick={(e) => setWithmyweightVal(e, 1)}>
												<button className={`button check ${parseInt(withmyweight) === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
												<span className="fl_l mt_02">Vlastní váha</span>
											</div>
										</div>
									</div>
									<div className="zero h1" />

									<div key="TYPEID21">
										<div className={`fl_l mr_2 ml_4 cr_p ${parseInt(typeId) === 1 ? 'button_disabled' : ''}`} onClick={(e) => setTypeId2Val(e, 1)}>
											<button className={`button check ${parseInt(typeId2) === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
											<span className="fl_l mt_02">Rychlost</span>
										</div>
									</div>
									<div className="zero h1" />
									<div key="TYPEID22">
										<div className={`fl_l mr_2 ml_4 cr_p ${parseInt(typeId) === 1 ? 'button_disabled' : ''}`} onClick={(e) => setTypeId2Val(e, 2)}>
											<button className={`button check ${parseInt(typeId2) === 2 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
											<span className="fl_l mt_02">Výdrž</span>
										</div>
									</div>
									<div className="zero h05" />
								</div>
							</div>
							<div className="popup_content_in_foot">
								<div className="popup_content_in_foot_in">
									<input type="submit" name="button_save" value="Uložit" className="button green mr_2" disabled={loading} />
									<button
										className="button red mr_2"
										onClick={(e) => {
											handleConfirmPopup(e);
										}}
									>
										Smazat
									</button>
									<button
										className="button"
										onClick={() => {
											closeEdit();
										}}
									>
										Zpět
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
