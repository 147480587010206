import React from 'react';

const FormText = React.forwardRef(({type, label, value, setValue, error, placeholder = '', maxLength}, ref) => {
	if (placeholder === '') {
		placeholder = label;
	}

	if (!maxLength) {
		maxLength = 255;
	}

	return (
		<div className="input-div">
			<div className="input-label">{label}</div>
			<div className="input-error">{error}</div>
			<input className="input-text" type={type} maxLength={maxLength} placeholder={placeholder} ref={ref} value={value} onChange={(e) => setValue(e.target.value)} />
		</div>
	);
});

export default FormText;
